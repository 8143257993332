import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  addBundle,
  updateBundle,
} from "../../../../actions/settings/bundleSettings/bundle";
import Util from "../../../../util/Util";
import { Button, Modal } from "react-bootstrap";
import CustomLabel from "../../../sub/CustomLabel";

class AddSAVModalAdmin extends React.Component {
  constructor(props) {
    super(props);

    var bundles = this.props.dataBundle || {};

    this.state = {
      select_bundle_SAV: bundles.select_bundle_SAV || "",
      code_LPP: bundles.code_LPP || "",
      amount_TTC: bundles.amount_TTC || "",
      loading: false,

      selectBundleSAVError: null,
      phoneError: null,
    };
  }

  onSubmit() {
    this.setState({ loading: true });

    var data = {
      select_bundle_SAV: this.state.select_bundle_SAV,
      code_LPP: this.state.code_LPP,
      amount_TTC: this.state.amount_TTC,
    };

    this.props.onAddBundle(data, () => this.props.close());
  }

  onChange(field, value) {
    this.setState({ [field]: value });

    if (field === "select_bundle_SAV") {
      if (!this.isNameUnique(value)) {
        this.setState({
          selectBundleSAVError: (
            <FormattedMessage id="Bundle.Name.Select.SAV" />
          ),
        });
        return;
      } else {
        this.setState({ selectBundleSAVError: null });
      }
    }
  }

  update(field, value) {
    if (this.props.bundles && !this.disabled()) {
      this.props.onUpdateBundle({
        bundleId: this.props.dataBundle._id,
        updatedField: field,
        updatedValue: value,
      });
    }
  }

  isNameUnique(select_bundle_SAV) {
    for (let bundle of this.props.bundles) {
      if (this.props.dataBundle && this.props.dataBundle._id === bundle._id)
        continue;

      if (
        bundle.select_bundle_SAV.toLowerCase() ===
        select_bundle_SAV.toLowerCase()
      ) {
        return false;
      }
    }

    return true;
  }

  disabled() {
    return (
      Util.emptyString(this.state.select_bundle_SAV) ||
      !this.isNameUnique(this.state.select_bundle_SAV) ||
      Util.emptyString(this.state.code_LPP) ||
      Util.emptyString(this.state.amount_TTC) ||
      this.state.loading
    );
  }

  render() {
    return (
      <div className="modal-bg show">
        <Modal
          show={true}
          onHide={() => this.props.close()}
          backdrop={"static"}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {!this.props.dataBundle._id ? (
                <FormattedMessage id="Add.Bundle" />
              ) : (
                <FormattedMessage id="Edit.Bundle" />
              )}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "Designation" })}
                htmlFor="select_bundle_SAV"
                className="col-12 col-sm-5 col-form-label"
                required
              />
              <div className="col-12 col-md-7">
                <input
                  type="text"
                  className="form-control"
                  id="select_bundle_SAV"
                  value={this.state.select_bundle_SAV}
                  onChange={(e) =>
                    this.onChange("select_bundle_SAV", e.target.value)
                  }
                  onBlur={(e) =>
                    this.update("select_bundle_SAV", e.target.value)
                  }
                />
                {this.state.selectBundleSAVError && (
                  <div className="text-danger">
                    <small>{this.state.selectBundleSAVError}</small>
                  </div>
                )}
              </div>
            </div>
            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({
                  id: "Montant.TTC.Initial",
                })}
                htmlFor="amount_TTC"
                className="col-12 col-sm-5 col-form-label"
                required
              />
              <div className="col-12 col-md-7">
                <input
                  type="number"
                  className="form-control"
                  id="commercialSign"
                  value={this.state.amount_TTC}
                  onChange={(e) => this.onChange("amount_TTC", e.target.value)}
                  onBlur={(e) => this.update("amount_TTC", e.target.value)}
                />
              </div>
            </div>
            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "Code.LPP" })}
                htmlFor="code_LPP"
                className="col-12 col-sm-5 col-form-label"
                required
              />
              <div className="col-12 col-md-7">
                <input
                  type="text"
                  className="form-control"
                  id="commercialSign"
                  value={this.state.code_LPP}
                  onChange={(e) => this.onChange("code_LPP", e.target.value)}
                  onBlur={(e) => this.update("code_LPP", e.target.value)}
                />
              </div>
            </div>
          </Modal.Body>

          {!this.props.dataBundle._id && (
            <Modal.Footer>
              <Button variant="secondary" onClick={() => this.props.close()}>
                <FormattedMessage id="Cancel" />
              </Button>
              <Button
                variant="info"
                onClick={() => this.onSubmit()}
                disabled={this.disabled()}
              >
                <FormattedMessage id="Add" />
              </Button>
            </Modal.Footer>
          )}
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    bundles: state.bundles,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateBundle: (data) => dispatch(updateBundle(data)),
    onAddBundle: (data, successCallback) =>
      dispatch(addBundle(data, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(AddSAVModalAdmin));
