import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  deleteHomeAssessmentDocument,
  updateShowHomeAssessment,
  updateHomeAssessmentDocumentData,
} from "../../actions/patients/patients";
import { deleteHomeAssessmentPdf } from "../../actions/homeAssessment/homeAssessment";
import DateUtil from "../../util/DateUtil";
import AddHomeAssessmentModal from "./AddHomeAssessmentModal";
import ConfirmationModal from "../sub/modals/ConfirmationModal";
import APIUrl from "../../APIUrl";
import Roles from "../../enums/Roles";
import VideoPlayer from "../sub/VideoPlayer";
import { UncontrolledTooltip } from "reactstrap";
import { Alert, Button } from "react-bootstrap";

class HomeAssessment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
      disabled: false,
    };
  }

  openModal(patient) {
    this.setState({
      modal: (
        <AddHomeAssessmentModal
          patient={patient}
          close={() => this.closeModal()}
        />
      ),
    });
  }

  openVideoModal(patient, homeAssessment) {
    let path =
      APIUrl.getHomeAssessmentVideos +
      patient._id +
      "/" +
      homeAssessment._id +
      "/";
    if (homeAssessment.videos) {
      this.setState({
        modal: (
          <VideoPlayer
            path={path}
            videos={homeAssessment.videos}
            close={() => this.closeModal()}
          />
        ),
      });
    }
  }

  closeModal() {
    this.setState({ modal: null });
  }

  delete(report) {
    const { patient } = this.props;

    if (!report || !patient) return;

    const modalContentMessage =
      report.type === "internal" ? (
        <FormattedMessage id="Confirm.Pdf.Removal" />
      ) : (
        <FormattedMessage id="Confirm.Tech.Document.Removal" />
      );

    const successCallback = () => {
      return report.type === "internal"
        ? this.props.onDeleteHomeAssessmentPdf(patient._id, report._id)
        : this.props.onDeleteHomeAssessmentDocument(patient._id, report._id);
    };

    this.setState({
      modal: (
        <ConfirmationModal
          centered={true}
          title={<FormattedMessage id="Confirm" />}
          content={modalContentMessage}
          successCallback={() => successCallback()}
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  sortByDate(a, b) {
    const getDate = (a) =>
      a.date ? Date.parse(a.date) : Date.parse(a.createdAt);
    return getDate(a) <= getDate(b) ? 1 : -1;
  }

  getCreationDate(report) {
    return report.type === "internal"
      ? DateUtil.toDate(report.createdAt)
      : DateUtil.toDate(report.date);
  }

  getTitle(report) {
    return report.type === "internal" ? report.pdf_title : report.title;
  }

  getFileSrc(report) {
    return report.type === "internal"
      ? APIUrl.getHomeAssessmentPdf +
          this.props.patient._id +
          "/" +
          report.pdf_title +
          "?token=" +
          APIUrl.jwtToken
      : APIUrl.getPatientHomeAssessmentFile +
          this.props.patient._id +
          "/" +
          report._id +
          "?token=" +
          APIUrl.jwtToken;
  }

  render() {
    const { patient, homeAssessments, user } = this.props;
    const { role } = user;

    if (!patient || !homeAssessments || !role) return null;

    let homeAssessmentsArray = homeAssessments.filter(
      (homeAssessment) => homeAssessment.patient_id === patient._id,
    );

    homeAssessmentsArray = homeAssessmentsArray
      .concat(patient.home_assessment)
      .sort(this.sortByDate);

    return (
      <React.Fragment>
        {this.props.user.role !== Roles.CLIENT ? (
          <button
            className="btn btn-info m-auto hoverable"
            onClick={(e) => this.openModal(patient)}
          >
            <FormattedMessage id="Report.External.Add" />
          </button>
        ) : (
          ""
        )}
        {(!homeAssessmentsArray || homeAssessmentsArray.length <= 0) && (
          <Alert variant="secondary" className="mt-3">
            <FormattedMessage id="Report.Empty" />
          </Alert>
        )}

        {homeAssessmentsArray && homeAssessmentsArray.length > 0 && (
          <table className="table tablee4vhp mt-3">
            <thead>
              <tr className="d-flex">
                <th className="col-2">
                  <FormattedMessage id="Date" />
                </th>
                <th className="col">
                  <FormattedMessage id="Title" />
                </th>
                {this.props.user.role === Roles.ADMIN && (
                  <th className="col-1">
                    <FormattedMessage id="Type" />
                  </th>
                )}
                <th className="col-3 text-center">
                  <FormattedMessage id="Actions" />
                </th>
              </tr>
            </thead>
            <tbody>
              {homeAssessmentsArray.map((report) => (
                <tr key={report._id} className={"d-flex"}>
                  <td className="col-2 d-flex align-items-center">
                    {this.getCreationDate(report)}
                  </td>

                  <td className="col d-flex align-items-center">
                    {this.getTitle(report)}
                  </td>

                  {this.props.user.role === Roles.ADMIN && (
                    <td className="col-1 d-flex align-items-center">
                      {this.props.intl.formatMessage({
                        id:
                          report.type === "internal" ? "Internal" : "External",
                      })}
                    </td>
                  )}
                  <td className="col-3 tdaction text-right">
                    <Button
                      id={"displayPdf" + report._id}
                      variant="outline-info"
                      size="sm"
                      className="mr-2"
                      onClick={() =>
                        window.open(
                          this.getFileSrc(report),
                          "_system",
                          "location=yes",
                        )
                      }
                    >
                      <i className="fa fa-download icon-big" />
                    </Button>
                    <UncontrolledTooltip
                      delay={{ show: 0, hide: 0 }}
                      placement="top"
                      target={"displayPdf" + report._id}
                    >
                      <FormattedMessage id="Display.Report" />
                    </UncontrolledTooltip>

                    <Button
                      id={"video" + report._id}
                      variant="outline-info"
                      size="sm"
                      className="mr-2"
                      onClick={() => this.openVideoModal(patient, report)}
                      disabled={
                        !report.videos || !report.videos.length ? true : false
                      }
                    >
                      <i
                        className="fa fa-file-video-o icon-big"
                        aria-hidden="true"
                      />
                    </Button>
                    <UncontrolledTooltip
                      delay={{ show: 0, hide: 0 }}
                      placement="top"
                      target={"video" + report._id}
                    >
                      <FormattedMessage id="Display.Videos" />
                    </UncontrolledTooltip>

                    {(role === Roles.ADMIN || role === Roles.SALES_REP) && (
                      <>
                        <Button
                          id={"deletePdf" + report._id}
                          variant="outline-info"
                          size="sm"
                          className="mr-2"
                          onClick={() => this.delete(report)}
                        >
                          <i className="fa fa-trash icon-big align-right" />
                        </Button>
                        <UncontrolledTooltip
                          delay={{ show: 0, hide: 0 }}
                          placement="top"
                          target={"deletePdf" + report._id}
                        >
                          <FormattedMessage id="Delete" />
                        </UncontrolledTooltip>
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    homeAssessments: state.homeAssessment,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateShowHomeAssessment: (data, cbk) =>
      dispatch(updateShowHomeAssessment(data, cbk)),
    onUpdateHomeAssessmentDocumentData: (data, cbk) =>
      dispatch(updateHomeAssessmentDocumentData(data, cbk)),
    onDeleteHomeAssessmentDocument: (
      patientId,
      homeAssessmentId,
      successCallback,
    ) =>
      dispatch(
        deleteHomeAssessmentDocument(
          patientId,
          homeAssessmentId,
          successCallback,
        ),
      ),
    onDeleteHomeAssessmentPdf: (patientId, homeAssessmentId, successCallback) =>
      dispatch(
        deleteHomeAssessmentPdf(patientId, homeAssessmentId, successCallback),
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(HomeAssessment));
