import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { updateBlocNotePatient } from "../../actions/patients/patients";
import CustomLabel from "../sub/CustomLabel";
import TextareaCounter from "react-textarea-counter";
import { Button, Modal } from "react-bootstrap";

class AddPatientNoteModal extends React.Component {
  constructor(props) {
    super(props);

    const { note } = this.props;

    this.state = {
      noteTitle: note ? note.title : "",
      noteContent: note ? note.content : "",
    };
  }

  addNote() {
    let patientNotes = [];
    if (this.props.patient.bloc_note)
      patientNotes = [...this.props.patient.bloc_note];

    let noteToAdd = {
      title: this.state.noteTitle,
      content: this.state.noteContent,
      date: Date.now(),
    };

    patientNotes.push(noteToAdd);

    this.props.onUpdateBlocNotePatient(
      {
        patientId: this.props.patient._id,
        updatedField: "bloc_note",
        updatedValue: patientNotes,
      },
      this.props.close(),
    );
  }

  updateNote() {
    let patientNotes = [...this.props.patient.bloc_note];

    for (let i = 0; i < patientNotes.length; i++) {
      if (this.props.note._id === patientNotes[i]._id) {
        patientNotes[i].title = this.state.noteTitle;
        patientNotes[i].date = Date.now();
        patientNotes[i].content = this.state.noteContent;
      }
    }

    this.props.onUpdateBlocNotePatient(
      {
        patientId: this.props.patient._id,
        updatedField: "bloc_note",
        updatedValue: patientNotes,
      },
      this.props.close(),
    );
  }

  onChange(field, value) {
    this.setState({
      [field]: value,
    });
  }

  disabled() {
    return (
      this.state.noteTitle.trim() === "" ||
      this.state.noteContent.trim() === "" ||
      (this.props.note &&
        this.props.note.title === this.state.noteTitle &&
        this.props.note.content === this.state.noteContent)
    );
  }

  render() {
    const { patient, note, intl } = this.props;

    return (
      <div className="modal-bg show">
        <Modal
          show={true}
          onHide={() => this.props.close()}
          backdrop={"static"}
          size={"lg"}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {note ? note.title : <FormattedMessage id="Patient.Note.Add" />}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <CustomLabel
              label={intl.formatMessage({ id: "Title" }) + " :"}
              htmlFor="title"
              labelClassName="col-12 col-sm-6 col-form-label"
              required
            />
            <div className="col-12">
              <input
                className="form-control"
                type="text"
                id="title"
                autoComplete="off"
                value={this.state.noteTitle}
                onChange={(e) => this.onChange("noteTitle", e.target.value)}
              />
            </div>

            <CustomLabel
              label={intl.formatMessage({ id: "Comment" }) + " :"}
              htmlFor="content"
              labelClassName="col-12 col-sm-6 col-form-label"
              required
            />
            <div className="col-12">
              <TextareaCounter
                countLimit={1000}
                className="textareaCounter"
                id="content"
                name="content"
                value={this.state.noteContent}
                onChange={(e) => this.onChange("noteContent", e.target.value)}
              />
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="info"
              onClick={(e) => {
                note ? this.updateNote() : this.addNote(patient);
              }}
              disabled={this.disabled()}
            >
              <FormattedMessage id={note ? "Update" : "Add"} />
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    //
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateBlocNotePatient: (patient, successCallback) =>
      dispatch(updateBlocNotePatient(patient, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(AddPatientNoteModal));
