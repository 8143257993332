import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";

import Util from "../../../../../util/Util";
import StringUtil from "../../../../../util/StringUtil";

import CustomLabel from "../../../../sub/CustomLabel";

import {
  addBrand,
  updateBrand,
} from "../../../../../actions/configurables/brands";
import { Button, Modal } from "react-bootstrap";

class BrandsAdminModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",

      nameError: null,
      disabled: false,
      modal: null,
    };

    if (this.props.brandId) {
      var brand = this.getBrand(this.props.brandId);

      this.state = {
        name: brand.name,

        nameError: null,
        disabled: false,
        modal: null,
      };
    }
  }

  getBrand(brandId) {
    for (let brand of this.props.brands) {
      if (brand._id === brandId) return brand;
    }
  }

  close() {
    this.props.closeModal();
  }

  onChange(value) {
    // Empty? Return error
    if (Util.emptyString(value.trim())) {
      return this.setState({
        name: value,
        nameError: <FormattedMessage id="Brand.Empty.Error" />,
      });
    }

    //Check that this brand does not already exist
    for (let brand of this.props.brands) {
      if (brand.name.toUpperCase() === value.toUpperCase().trim()) {
        return this.setState({
          name: value,
          nameError: <FormattedMessage id="Brand.Already.Exists" />,
        });
      }
    }

    this.setState({
      name: value,
      nameError: null,
      disabled: false,
    });
  }

  add() {
    this.setState({ disabled: true });

    let data = {
      name: this.state.name.toUpperCase().trim(),
    };

    let callback = () => {
      this.close();
    };

    this.props.onAddBrand(data, callback);
  }

  update(fieldName, fieldValue) {
    if (!this.props.brandId || this.state.nameError) return;

    let data = {
      brandId: this.props.brandId,
      fieldName: fieldName,
      fieldValue: fieldValue,
    };

    this.props.onUpdateBrand(data);
  }

  render() {
    // Detect similarities to avoid duplication
    let brands = [];
    let simBrands = [];

    for (let brand of this.props.brands) brands.push(brand.name);
    outer: for (let m0 of brands) {
      let m1 = this.state.name;

      if (m0 === m1) continue;

      for (let s of simBrands) {
        if ((s[0] === m1 && s[1] === m0) || (s[0] === m0 && s[1] === m1)) {
          continue outer;
        }
      }
      // console.log(m0 + " / " + m1 + " : " + StringUtil.similarity(m0, m1));
      if (StringUtil.similarity(m0, m1) > 0.8) {
        simBrands.push([m0, m1]);
      }
    }

    return (
      <React.Fragment>
        <div className="modal-bg show">
          <Modal
            show={true}
            onHide={() => this.close()}
            backdrop={"static"}
            size={"lg"}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {!this.props.brandId ? (
                  <FormattedMessage id="Add.Brand" />
                ) : (
                  <FormattedMessage id="Brand.Edit" />
                )}
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <div className="col-12 col-lg-8">
                <div className="form-group row">
                  <CustomLabel
                    label={this.props.intl.formatMessage({ id: "Name" })}
                    htmlFor="name"
                    labelClassName="col-12 col-md-4 col-form-label"
                    required
                  />
                  <div id="name" className="col-12 col-md-8">
                    <input
                      type="text"
                      className="form-control"
                      id="brandName"
                      value={this.state.name}
                      onChange={(e) =>
                        this.onChange(e.target.value.toUpperCase())
                      }
                      onBlur={(e) =>
                        this.update("name", e.target.value.toUpperCase())
                      }
                    />
                    <small className="text-danger">
                      {this.state.nameError}
                    </small>
                  </div>
                </div>
              </div>
              {!this.props.brandId && simBrands.length > 0 && (
                <div className="alert alert-warning m-auto" role="alert">
                  <div className="">
                    <FormattedMessage id="Brand.Similarities" /> :
                  </div>
                  <ul className="">
                    {simBrands.map((brand, index) => (
                      <li key={index}>{brand[0]}</li>
                    ))}
                  </ul>
                  <div>
                    <FormattedMessage id="Duplicate.fix" />
                  </div>
                </div>
              )}
            </Modal.Body>

            {!this.props.brandId && (
              <Modal.Footer>
                <Button variant="secondary" onClick={() => this.close()}>
                  <FormattedMessage id="Cancel" />
                </Button>
                <Button
                  variant="btn btn-info"
                  onClick={() => this.add()}
                  disabled={
                    this.state.disabled ||
                    this.state.nameError ||
                    this.state.name === ""
                  }
                >
                  <FormattedMessage id="Add" />
                </Button>
              </Modal.Footer>
            )}
          </Modal>
        </div>

        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    brands: state.brands,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddBrand: (data, successCallback) =>
      dispatch(addBrand(data, successCallback)),
    onUpdateBrand: (data) => dispatch(updateBrand(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(BrandsAdminModal));
