import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import {
  addCollaboratorType,
  removeCollaboratorType,
} from "../../../../../actions/configurables/collaboratorTypes";
import { UncontrolledTooltip } from "reactstrap";
import { Alert, Card } from "react-bootstrap";

class CollaboratorTypesRow extends React.Component {
  check(collaboratorTypeId) {
    let { collaborators } = this.props;
    let items = collaborators;
    let result = [];

    for (let item of items) {
      if (item.collaborator_type_id === collaboratorTypeId) result.push(item);
    }
    return result;
  }

  modalDeleteCollaboratorType(modalTitle, modalContent, successCallback) {
    let { collaboratorType, openConfModal } = this.props;
    let errors = this.check(collaboratorType._id);

    if (errors.length > 0) {
      this.openDataIntegrityModal(errors);
    } else {
      openConfModal(modalTitle, modalContent, successCallback);
    }
  }

  deleteCollaboratorType(collaboratorTypeId) {
    var data = {
      collaboratorTypeId: collaboratorTypeId,
      collaborators: this.props.collaborators,
    };
    this.props.onDeleteCollaboratorType(data);
  }

  openDataIntegrityModal(dataProblems) {
    var errorModalTitle = <FormattedMessage id="Error" />;
    var errorModalContent = (
      <div>
        <Alert variant={"danger"}>
          <FormattedMessage
            id="Collaborator.Type.Can.Not.Be.Deleted"
            values={{ collaboratorType: this.props.collaboratorType.name }}
          />
        </Alert>
        <table className="table table-striped tablee4vhp">
          <thead>
            <tr className="d-flex">
              <th scope="col" className="col col-3">
                <FormattedMessage id="First.Name" />
              </th>
              <th scope="col" className="col col-3">
                <FormattedMessage id="Name" />
              </th>
            </tr>
          </thead>
          <tbody>
            {dataProblems.length > 0 &&
              dataProblems.map((row, index) => {
                return (
                  <tr key={index} className="d-flex">
                    <td className="col col-3">{row.first_name}</td>
                    <td className="col col-3">{row.name}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    );

    this.props.openErrorModal(errorModalTitle, errorModalContent);
  }

  render() {
    let { collaboratorType, key } = this.props;

    // 'Delete brand modal setup
    var modalTitle = <FormattedMessage id="Confirmation" />;
    var modalContent = (
      <React.Fragment>
        <Card bg={"danger"} text={"light"}>
          <Card.Header>
            <i className="fa fa-warning mr-2 text-white"></i>
            <FormattedMessage id="Warning" />
          </Card.Header>
          <Card.Body>
            <Card.Text>
              <FormattedMessage
                id="Delete.Collaborator.Types"
                values={{ collaboratorType: collaboratorType.name }}
              />
            </Card.Text>
          </Card.Body>
        </Card>
      </React.Fragment>
    );
    var successCallback = () =>
      this.deleteCollaboratorType(collaboratorType._id);

    return (
      <React.Fragment>
        <tr key={key} className="tre4coll">
          <td>{collaboratorType.name}</td>
          <td className="text-center tdaction">
            <i
              id={"delete" + collaboratorType._id}
              className="fa fa-trash icon-big"
              onClick={() =>
                this.modalDeleteCollaboratorType(
                  modalTitle,
                  modalContent,
                  successCallback,
                )
              }
            ></i>
            <UncontrolledTooltip
              delay={{ show: 0, hide: 0 }}
              placement="top"
              target={"delete" + collaboratorType._id}
            >
              <FormattedMessage id="Delete" />
            </UncontrolledTooltip>
          </td>
        </tr>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.user.lang,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddCollaboratorType: (data, successCallback) =>
      dispatch(addCollaboratorType(data, successCallback)),
    onDeleteCollaboratorType: (data) => dispatch(removeCollaboratorType(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CollaboratorTypesRow);
