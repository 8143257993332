import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import AddPatientTrialModal from "./trials/AddPatientTrialModal";
import PatientTrialRow from "./trials/PatientTrialRow";
import { updPatient } from "../../actions/patients/patients";
import { removeTrial } from "../../actions/trials/trials";
import ConfirmationModal from "../sub/modals/ConfirmationModal";
import Roles from "../../enums/Roles";
import { Alert } from "react-bootstrap";

class PatientTrials extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
    };
  }

  openModal(trials) {
    this.setState({
      modal: (
        <AddPatientTrialModal
          trials={trials}
          closeModal={() => this.closeModal()}
          patient={this.props.patient}
        />
      ),
    });
  }

  closeModal() {
    this.setState({ modal: null });
  }

  delete(trial) {
    if (!trial) return;

    const onConfirm = () => {
      // Send to BE
      var data = {
        patient_id: trial.patient_id,
        trial_id: trial._id,
      };
      this.props.onRemoveTrial(data, () => {});
    };

    this.setState({
      modal: (
        <ConfirmationModal
          isOpen={true}
          title={<FormattedMessage id="Confirm" />}
          content={<FormattedMessage id="Confirm.Trial.Removal" />}
          successCallback={onConfirm}
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  render() {
    const { trials, patient, user } = this.props;

    var patientTrialsNode;

    if (!patient) return null;

    const patientTrials = trials.filter(
      (trial) => trial.patient_id === patient._id,
    );

    if (patientTrials && patientTrials.length > 0) {
      patientTrialsNode = patientTrials.map((trial) => {
        let wheelchairBrandId = "";
        let wheelchairModelId = "";

        if (patient.wheelchairs) {
          for (const wheelchair of patient.wheelchairs) {
            if (wheelchair._id === trial.loan_material) {
              wheelchairBrandId = wheelchair.brand_id;
              wheelchairModelId = wheelchair.model_id;
            }
          }
        }

        return (
          <PatientTrialRow
            key={trial._id}
            trial={trial}
            openModal={(trial) => this.openModal(trial)}
            delete={(trial) => this.delete(trial)}
            patient={patient}
            wheelchairBrandId={wheelchairBrandId}
            wheelchairModelId={wheelchairModelId}
          />
        );
      });
    }

    return (
      <React.Fragment>
        {(user.role === Roles.ADMIN || user.role === Roles.SALES_REP) && (
          <button
            className="btn btn-info m-auto hoverable"
            onClick={(e) => this.openModal(trials)}
          >
            <FormattedMessage id="Patient.Trial.Add" />
          </button>
        )}
        {/* <button className="btn btn-info m-auto hoverable" onClick={(e) => this.openModal(trials)}>
                    <FormattedMessage id="Patient.Trial.Evaluate" />
                </button> */}

        {user.role === Roles.ADMIN &&
          (!patientTrials || patientTrials.length === 0) && (
            <Alert variant="secondary" className="mt-3">
              <FormattedMessage id={"Empty.Patient.Trials"} />
            </Alert>
          )}

        {user.role === Roles.SALES_REP &&
          (!patientTrials || patientTrials.length === 0) && (
            <Alert variant="secondary" className="mt-3">
              <FormattedMessage id={"Empty.Patient.Trials"} />
            </Alert>
          )}

        {user.role === Roles.CLIENT &&
          (!patientTrials || patientTrials.length === 0) && (
            <Alert variant="secondary" className="mt-3">
              <FormattedMessage id={"Empty.Patient.Therapist.Trials"} />
            </Alert>
          )}

        {patientTrials && patientTrials.length > 0 && (
          <table className="table tablee4vhp mt-3">
            <thead>
              <tr className="tablerowhead">
                <th className="d-none d-md-table-cell">
                  <FormattedMessage id="Title" />{" "}
                  <i className="fa fa-chevron-down float-right mr-5"></i>
                </th>
                <th className="d-none d-md-table-cell">
                  <FormattedMessage id="Brand" />{" "}
                  <i className="fa fa-chevron-down float-right mr-5"></i>
                </th>
                <th className="d-none d-md-table-cell">
                  <FormattedMessage id="Model" />{" "}
                  <i className="fa fa-chevron-down float-right mr-5"></i>
                </th>
                <th className="d-none d-md-table-cell">
                  <FormattedMessage id="Picture" />{" "}
                  <i className="fa fa-chevron-down float-right mr-5"></i>
                </th>
                <th className="d-none d-md-table-cell">
                  <FormattedMessage id="Trial.Start" />{" "}
                  <i className="fa fa-chevron-down float-right mr-5"></i>
                </th>
                <th className="d-none d-md-table-cell">
                  <FormattedMessage id="Trial.End" />{" "}
                  <i className="fa fa-chevron-down float-right mr-5"></i>
                </th>
                <th className="d-none d-md-table-cell">
                  <FormattedMessage id="Videos" />{" "}
                  <i className="fa fa-chevron-down float-right mr-5"></i>
                </th>
                <th className="d-none d-md-table-cell">
                  <FormattedMessage id="Status" />{" "}
                  <i className="fa fa-chevron-down float-right mr-5"></i>
                </th>
                <th className="d-none d-md-table-cell">
                  <FormattedMessage id="Actions" />{" "}
                  <i className="fa fa-chevron-down float-right mr-5"></i>
                </th>
              </tr>
            </thead>
            <tbody>{patientTrialsNode}</tbody>
          </table>
        )}

        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    trials: state.trials,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdPatient: (patient, successCallback) =>
      dispatch(updPatient(patient, successCallback)),
    onRemoveTrial: (data, successCallback) =>
      dispatch(removeTrial(data, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(PatientTrials));
