import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import DateUtil from "../../util/DateUtil";
import AddPatientSAVForfaitModal from "./sav/AddPatientSAVForfaitModal";
import { getPatientSAV } from "../../actions/savs/savs";
import Roles from "../../enums/Roles";
import AddPatientSAVDocumentModal from "./sav/AddPatientSAVDocumentModal";
import APIUrl from "../../APIUrl";
import Maths from "../../util/Maths";
import { UncontrolledTooltip } from "reactstrap";
import { Doughnut } from "react-chartjs-2";
import { Alert } from "react-bootstrap";

class PatientSAV extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
      collapsedSAVId: null,
    };
  }

  componentDidMount() {
    const { patient } = this.props;
    if (!patient) return;

    this.props.onGetPatientSAV(patient._id);
  }

  openAddSAVForfaitModal(patient) {
    this.setState({
      modal: (
        <AddPatientSAVForfaitModal
          patient={patient}
          onClose={() => this.closeModal()}
        />
      ),
    });
  }

  openPatientSAVDetailModal(patient, sav) {
    this.setState({
      modal: (
        <AddPatientSAVDocumentModal
          patient={patient}
          sav={sav}
          onClose={() => this.closeModal()}
        />
      ),
    });
  }

  closeModal() {
    this.setState({ modal: null });
  }

  collapse(savId) {
    if (this.state.collapsedSAVId === savId)
      this.setState({ collapsedSAVId: null });
    else this.setState({ collapsedSAVId: savId });
  }

  render() {
    const { user, patient, savs, bundles } = this.props;

    if (!patient) return null;

    const savsNode = savs.map((sav) => {
      const first_use = sav.documents.sort((a, b) =>
        a.date > b.date ? 1 : -1,
      );

      const bundle = bundles.find((b) => b._id === sav.bundle_id);
      if (!bundle) return null;

      const renewalDate = DateUtil.addYears(sav.start_date, 1);
      const daysToRenewal = DateUtil.daysBetween(renewalDate, new Date());
      var renewalDateClassname = "";
      if (daysToRenewal < 30 && daysToRenewal > 0)
        renewalDateClassname = "text-warning";

      var leftAmount = Maths.round(parseFloat(bundle.amount_TTC));

      var usedAmountTotal = 0;
      if (sav.documents) {
        for (let doc of sav.documents)
          usedAmountTotal += Maths.round(doc.montant_TTC);
      }
      var amountLeftTotal = Maths.round(
        Maths.round(bundle.amount_TTC) - Maths.round(usedAmountTotal),
      );

      var lastDate = null;
      if (sav.documents && sav.documents.length > 0) {
        const sorted = sav.documents.sort(
          (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
        );
        lastDate = sorted[sorted.length - 1].date;
      }

      var data = {
        labels: [
          this.props.intl.formatMessage({ id: "Used" }) +
            ": " +
            Maths.round(usedAmountTotal) +
            "€",
          this.props.intl.formatMessage({ id: "Left" }) +
            ": " +
            Maths.round(amountLeftTotal) +
            "€",
        ],
        datasets: [
          {
            data: [usedAmountTotal, amountLeftTotal],
            backgroundColor: ["#DC143C", "green"],
            pointBackgroundColor: ["#DC143C", "green"],
            pointBorderColor: ["rgba(0, 0, 0, 0)"],
            borderColor: ["#DC143C", "green"],
            borderWidth: [2],
          },
        ],
      };

      return (
        <React.Fragment key={sav._id}>
          <tr className={daysToRenewal <= 0 ? "text-muted font-italic" : ""}>
            <td>{bundle.code_LPP}</td>
            <td className="text-left">
              <strong>{bundle.select_bundle_SAV.split("-")[0]}</strong> <br />
              {bundle.select_bundle_SAV.split("-")[1]}
            </td>
            <td>
              <Doughnut data={data} />
            </td>

            <td>
              {lastDate !== null && lastDate !== undefined && (
                <div>
                  <strong>
                    <FormattedMessage id="First" /> :
                  </strong>{" "}
                  {DateUtil.toDate(first_use[0].date)} <br />
                  <strong>
                    <FormattedMessage id="Last" /> :
                  </strong>{" "}
                  {lastDate && DateUtil.toDate(lastDate)}
                </div>
              )}
            </td>
            <td className={renewalDateClassname}>
              <strong>
                <FormattedMessage id="Start" /> : <br />{" "}
              </strong>{" "}
              {DateUtil.toDate(sav.start_date)}
              <br />
              <strong>
                <FormattedMessage id="Renewal" /> :{" "}
              </strong>
              {DateUtil.toDate(renewalDate)}
            </td>
            <td style={{ border: "1px solid red", minWidth: 100 }}>
              <i
                id={"displaySAV-" + sav._id}
                className={
                  "fa icon-big d-inline-block align-middle " +
                  (this.state.collapsedSAVId === sav._id
                    ? " fa-sort-up "
                    : " fa-sort-down ")
                }
                onClick={(e) => {
                  if (sav.documents && sav.documents.length > 0)
                    this.collapse(sav._id);
                }}
                disabled={!sav.documents || sav.documents.length === 0}
                style={{
                  paddingTop: this.state.collapsedSAVId === sav._id ? 8 : 0,
                  marginRight: 7,
                }}
              />
              <UncontrolledTooltip
                delay={{ show: 0, hide: 0 }}
                placement="top"
                target={"displaySAV-" + sav._id}
              >
                {this.state.collapsedSAVId ? (
                  <FormattedMessage id="No.Display.Invoice" />
                ) : (
                  <FormattedMessage id="Display.Invoice" />
                )}
              </UncontrolledTooltip>

              {(user.role === Roles.ADMIN || user.role === Roles.SALES_REP) && (
                <i
                  id={"add-" + sav._id}
                  className="fa fa-plus icon-big hoverable clickable d-inline-block align-top "
                  style={{ fontSize: 16, paddingTop: 5, textAlign: "center" }}
                  onClick={(e) => this.openPatientSAVDetailModal(patient, sav)}
                />
              )}
              {(user.role === Roles.ADMIN || user.role === Roles.SALES_REP) && (
                <UncontrolledTooltip
                  delay={{ show: 0, hide: 0 }}
                  placement="top"
                  target={"add-" + sav._id}
                >
                  <FormattedMessage id="Add.Invoice" />
                </UncontrolledTooltip>
              )}
            </td>
          </tr>
          {this.state.collapsedSAVId === sav._id && (
            <tr
              className="p-1 table-visible-blue.show"
              key={sav._id + "-table"}
            >
              <td colSpan="1000" className="p-1">
                <table
                  className={
                    "table table-striped tablee4vhp p-0 mb-0 " +
                    (daysToRenewal <= 0 ? "text-muted font-italic" : "")
                  }
                >
                  <thead>
                    <tr>
                      <th>
                        <FormattedMessage id="Bill" />
                      </th>
                      <th>
                        <FormattedMessage id="Amount.Incl.Taxes" />
                      </th>
                      <th>
                        <FormattedMessage id="Forfait.Left" />
                      </th>
                      <th>
                        <FormattedMessage id="Date" />
                      </th>
                      <th>
                        <FormattedMessage id="PDF" />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {sav.documents &&
                      sav.documents.map((doc) => {
                        leftAmount -= parseFloat(doc.montant_TTC);

                        return (
                          <tr key={doc._id}>
                            <td>{doc.ref_facture}</td>
                            <td>{Maths.round(doc.montant_TTC)}€</td>
                            <td
                              className={leftAmount <= 0 ? "text-danger" : ""}
                            >
                              {Maths.round(leftAmount)}€
                            </td>
                            <td>{DateUtil.toDate(doc.date)}</td>
                            <td className="tdaction">
                              <a
                                role="button"
                                href={
                                  APIUrl.getPatientSAVFile +
                                  patient._id +
                                  "/" +
                                  doc.file_name +
                                  "?token=" +
                                  APIUrl.jwtToken
                                }
                                rel="noopener noreferrer"
                                download={sav.file_name}
                                target="_blank"
                              >
                                <i
                                  id={"display-" + doc._id}
                                  className="fa fa-download icon-big"
                                  disabled={false}
                                />
                                <UncontrolledTooltip
                                  delay={{ show: 0, hide: 0 }}
                                  placement="top"
                                  target={"display-" + doc._id}
                                >
                                  <FormattedMessage id="Display.Document" />
                                </UncontrolledTooltip>
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </td>
            </tr>
          )}
        </React.Fragment>
      );
    });

    return (
      <React.Fragment>
        {this.props.user.role !== Roles.CLIENT && (
          <button
            className="btn btn-info m-auto hoverable"
            onClick={(e) => this.openAddSAVForfaitModal(patient)}
          >
            <FormattedMessage id="Patient.SAV.Forfait.Add" />
          </button>
        )}

        {savs && savs.length > 0 ? (
          <table className="table col-12 tablee4vhp mt-3 text-center">
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="Forfait" />
                </th>
                <th>
                  <FormattedMessage id="Designation" />
                </th>
                <th>
                  <FormattedMessage id="Global.Amount" />
                </th>
                <th>
                  <FormattedMessage id="Use" />
                </th>
                <th>
                  <FormattedMessage id="Calendar.Date" />
                </th>
                <th>
                  <FormattedMessage id="Actions" />
                </th>
              </tr>
            </thead>
            <tbody>{savsNode}</tbody>
          </table>
        ) : (
          <Alert variant="secondary" className="mt-3">
            <FormattedMessage id={"No.SAV.Forfait.Perception"} />
          </Alert>
        )}

        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    bundles: state.bundles,
    savs: state.savs,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetPatientSAV: (patientId) => dispatch(getPatientSAV(patientId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(PatientSAV));
