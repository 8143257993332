import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import TrialPerceptionModalAdmin from "./TrialPerceptionModalAdmin";
import {
  addTrialPerception,
  deleteTrialPerception,
  updateTrialPerception,
} from "../../../../actions/settings/trialPerceptions/trialPerceptions";
import ConfirmationModal from "../../../sub/modals/ConfirmationModal";
import { UncontrolledTooltip } from "reactstrap";
import { Alert } from "react-bootstrap";

class TrialPerceptionsAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
    };
  }

  addTrialPerception(perception) {
    var data = {
      name: perception,
    };
    this.props.onAddTrialPerception(data, () => {
      this.closeModal();
    });
  }

  deleteTrialPerception(trialPerception) {
    var data = {
      _id: trialPerception._id,
    };
    this.props.onDeleteTrialPerception(data, () => {
      this.closeModal();
    });
  }

  updateTrialPerception(trialPerception) {
    this.props.onUpdateTrialPerception(trialPerception, () => {
      this.closeModal();
    });
  }

  openAddModal() {
    this.setState({
      modal: (
        <TrialPerceptionModalAdmin
          addTrialPerception={(trialPerception) =>
            this.addTrialPerception(trialPerception)
          }
          trialPerceptions={this.props.trialPerceptions}
          close={() => this.closeModal()}
        />
      ),
    });
  }

  openEditModal(trialPerception) {
    this.setState({
      modal: (
        <TrialPerceptionModalAdmin
          updateTrialPerception={(trialPerception) =>
            this.updateTrialPerception(trialPerception)
          }
          trialPerceptions={this.props.trialPerceptions}
          trialPerception={trialPerception}
          close={() => this.closeModal()}
        />
      ),
    });
  }

  openDeleteModal(trialPerception) {
    var title = <FormattedMessage id="Confirm" />;
    var content = <FormattedMessage id="Delete.Perception" />;
    var successCallback = (e) => this.deleteTrialPerception(trialPerception);

    this.setState({
      modal: (
        <ConfirmationModal
          isOpen={true}
          title={title}
          content={content}
          successCallback={successCallback}
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  closeModal() {
    this.setState({ modal: null });
  }

  render() {
    var trialPerceptions = this.props.trialPerceptions;
    // console.log("trialPerceptions", trialPerceptions);
    if (!trialPerceptions) {
      return (
        <React.Fragment>
          <label className="d-block">
            <FormattedMessage id="Trial.Perception" />
          </label>
          <button
            className="btn btn-info m-auto hoverable"
            onClick={(e) => this.openModal()}
          >
            <FormattedMessage id="Add.Trial.Perception" />
          </button>

          <Alert variant={"secondary"} className="mt-3">
            <FormattedMessage id="No.Trial.Perception" />
          </Alert>

          {this.state.modal}
        </React.Fragment>
      );
    }

    var trialPerceptionsNode = trialPerceptions.map((trialPerception) => {
      return (
        <tr
          key={"trialPerception-" + trialPerception._id}
          className={"order-tr order-tr"}
        >
          <td className="d-md-table-cell mw-200 align-middle">
            {trialPerception.name}
          </td>
          <td className="d-md-table-cell mw-200 align-middle">
            <i
              id={"trialPerceptionModify" + trialPerception._id}
              className="fa fa-edit icon-big"
              onClick={(e) => this.openEditModal(trialPerception)}
            />
            <UncontrolledTooltip
              delay={{ show: 0, hide: 0 }}
              placement="top"
              target={"trialPerceptionModify" + trialPerception._id}
            >
              <FormattedMessage id="Modify" />
            </UncontrolledTooltip>
            <i
              id={"trialPerceptionDelete" + trialPerception._id}
              className="fa fa-trash icon-big"
              onClick={(e) => this.openDeleteModal(trialPerception)}
            />
            <UncontrolledTooltip
              delay={{ show: 0, hide: 0 }}
              placement="top"
              target={"trialPerceptionDelete" + trialPerception._id}
            >
              <FormattedMessage id="Delete" />
            </UncontrolledTooltip>
          </td>
        </tr>
      );
    });

    return (
      <React.Fragment>
        <button
          className="btn btn-info m-auto hoverable"
          onClick={(e) => this.openAddModal()}
        >
          <FormattedMessage id="Add.Trial.Perception" />
        </button>

        {trialPerceptions && trialPerceptions.length > 0 ? (
          <table className="col-6 table tablee4vhp mt-3">
            <thead>
              <tr className="tablerowhead">
                <th className="d-md-table-cell">
                  <FormattedMessage id="Name" />{" "}
                  <i className="fa fa-chevron-down float-right mr-4" />
                </th>
                <th className="d-md-table-cell">
                  <FormattedMessage id="Actions" />{" "}
                  <i className="fa fa-chevron-down float-right mr-2" />
                </th>
              </tr>
            </thead>
            <tbody>{trialPerceptionsNode}</tbody>
          </table>
        ) : (
          <Alert variant={"secondary"} className="mt-3">
            <FormattedMessage id="No.Trial.Perception" />
          </Alert>
        )}

        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    trialPerceptions: state.trialPerceptions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddTrialPerception: (data, successCallback) =>
      dispatch(addTrialPerception(data, successCallback)),
    onDeleteTrialPerception: (data, successCallback) =>
      dispatch(deleteTrialPerception(data, successCallback)),
    onUpdateTrialPerception: (data, successCallback) =>
      dispatch(updateTrialPerception(data, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(TrialPerceptionsAdmin));
