import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import ModelWheelchairModal from "./ModelWheelchairModal";
import {
  addWheelchair,
  deleteWheelchair,
} from "../../actions/patients/patients";
import ConfirmationModal from "../sub/modals/ConfirmationModal";
import APIUrl from "../../APIUrl";
import Roles from "../../enums/Roles";
import { UncontrolledTooltip } from "reactstrap";
import { Alert } from "react-bootstrap";

class ModelWheelchair extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
    };
  }

  getBrand(brandId) {
    for (let brand of this.props.brands) {
      if (brand._id === brandId) return brand;
    }
  }

  getModel(modelId) {
    for (let model of this.props.models) {
      if (model._id === modelId) return model;
    }
  }

  openModal(patient, wheelchair) {
    this.setState({
      modal: (
        <ModelWheelchairModal
          patient={patient}
          wheelchair={wheelchair}
          close={() => this.closeModal()}
        />
      ),
    });
  }

  closeModal() {
    this.setState({ modal: null });
  }

  delete(patient, wheelchair) {
    var patientId = patient._id;
    var wheelchairId = wheelchair._id;

    const onConfirm = () => {
      this.props.onDeleteWheelchair(patientId, wheelchairId);
    };

    this.setState({
      modal: (
        <ConfirmationModal
          isOpen={true}
          title={<FormattedMessage id="Confirm" />}
          content={<FormattedMessage id="Confirm.Wheelchair.Removal" />}
          successCallback={onConfirm}
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  render() {
    const { patient } = this.props;
    var patientwheelchairsNode;
    if (patient) {
      patientwheelchairsNode = patient.wheelchairs.map((h) => {
        return (
          <tr key={"helper-" + h._id} className={"order-tr order-tr"}>
            <td className="d-md-table-cell mw-200 align-middle">
              <img
                src={
                  APIUrl.getModelImg +
                  h.model_id +
                  "/" +
                  h.model_name +
                  "/" +
                  Math.random() +
                  "?token=" +
                  APIUrl.jwtToken
                }
                width="200"
                height="200"
                alt="logo"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "/images/placeholder_120x120.png";
                }}
              />
            </td>
            <td className="d-md-table-cell mw-200 align-middle">
              {this.getBrand(h.brand_id).name}
            </td>
            <td className="d-md-table-cell mw-200 align-middle">
              {this.getModel(h.model_id).name}
            </td>
            <td className="d-none d-md-table-cell mw-200 align-middle">
              {h.option}
            </td>
            <td className="d-none d-md-table-cell mw-200 align-middle">
              {h.adaptation}
            </td>
            {this.props.user.role !== Roles.CLIENT && (
              <td className="d-none d-md-table-cell mw-200 align-middle">
                <i
                  id={"modify-" + h._id}
                  className="fa fa-edit icon-big tdaction"
                  onClick={(e) => this.openModal(patient, h)}
                ></i>
                <UncontrolledTooltip
                  delay={{ show: 0, hide: 0 }}
                  placement="top"
                  target={"modify-" + h._id}
                >
                  <FormattedMessage id="Modify" />
                </UncontrolledTooltip>
                <i
                  id={"delete-" + h._id}
                  className="fa fa-trash icon-big tdaction"
                  onClick={(e) => this.delete(patient, h)}
                ></i>
                <UncontrolledTooltip
                  delay={{ show: 0, hide: 0 }}
                  placement="top"
                  target={"delete-" + h._id}
                >
                  <FormattedMessage id="Delete" />
                </UncontrolledTooltip>
              </td>
            )}
          </tr>
        );
      });
    }

    return (
      <React.Fragment>
        {this.props.user.role !== Roles.CLIENT && (
          <button
            className="btn btn-info m-auto hoverable"
            onClick={(e) => this.openModal(patient)}
          >
            <FormattedMessage id="Patient.Wheelchair.Add" />
          </button>
        )}

        {(!patient ||
          !patient.wheelchairs ||
          patient.wheelchairs.length <= 0) && (
          <Alert variant="secondary" className="mt-3">
            <FormattedMessage id="Empty.Patient.Wheelchair" />
          </Alert>
        )}

        {patient && patient.wheelchairs && patient.wheelchairs.length > 0 && (
          <table className="table tablee4vhp mt-3">
            <thead>
              <tr className="tablerowhead">
                <th className="d-md-table-cell">
                  <FormattedMessage id="Photo" />{" "}
                  <i className="fa fa-chevron-down float-right mr-5"></i>
                </th>
                <th className="d-md-table-cell">
                  <FormattedMessage id="Brand" />{" "}
                  <i className="fa fa-chevron-down float-right mr-5"></i>
                </th>
                <th className="d-md-table-cell">
                  <FormattedMessage id="Model" />{" "}
                  <i className="fa fa-chevron-down float-right mr-5"></i>
                </th>
                <th className="d-none d-md-table-cell">
                  <FormattedMessage id="Option.Wheelchair" />{" "}
                  <i className="fa fa-chevron-down float-right mr-5"></i>
                </th>
                <th className="d-none d-md-table-cell">
                  <FormattedMessage id="Adaptation.Wheelchair" />{" "}
                  <i className="fa fa-chevron-down float-right mr-5"></i>
                </th>
                {this.props.user.role !== Roles.CLIENT && (
                  <th className="d-none d-md-table-cell">
                    <FormattedMessage id="Actions" />{" "}
                    <i className="fa fa-chevron-down float-right mr-5"></i>
                  </th>
                )}
              </tr>
            </thead>
            <tbody>{patientwheelchairsNode}</tbody>
          </table>
        )}

        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    brands: state.brands,
    models: state.models,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddWheelchair: (patient, successCallback) =>
      dispatch(addWheelchair(patient, successCallback)),
    onDeleteWheelchair: (patientId, wheelchairId) =>
      dispatch(deleteWheelchair(patientId, wheelchairId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(ModelWheelchair));
