import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import {
  updateEquipmentType,
  addImgEquipmentType,
} from "../../actions/patients/patients";
import FileDropZone from "../sub/FileDropZone";
import APIUrl from "../../APIUrl";
import Roles from "../../enums/Roles";
import { Alert } from "react-bootstrap";

// import Util from '../../util/Util';

class EquipmentType extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      brand: "",
      model: "",
      nbr_serial: "",
      acquisition: "",
      accessory: "",
      logo: false,
      disabledDropZone: false,
    };

    if (this.props.patient && this.props.patient.equipment_type) {
      this.state = {
        show: this.props.patient.equipment_type.show || false,
        brand: this.props.patient.equipment_type.brand || "",
        model: this.props.patient.equipment_type.model || "",
        nbr_serial: this.props.patient.equipment_type.nbr_serial || "",
        acquisition: this.props.patient.equipment_type.acquisition || "",
        accessory: this.props.patient.equipment_type.accessory || "",
        logo: this.props.patient.equipment_type.logo || false,
        disabledDropZone: false,
      };
    }
  }

  onChange(key, value) {
    if (key === "show") {
      this.setState({ show: !this.state.show }, () => this.update());
    } else {
      this.setState({ [key]: value });
    }
  }

  update(cbk) {
    var data = {
      patientId: this.props.patient._id,
      updatedField: "equipment_type",
      updatedValue: this.state,
    };

    this.props.onUpdateEquipmentType(data, cbk);
  }

  onDropFile(file, clearCallback) {
    let formData = new FormData();
    formData.append(file.name, file);

    this.setState({ disabledDropZone: true });

    var successCallback = () => {
      clearCallback();
      this.setState({ disabledDropZone: false });
    };

    const updLogoField = () => {
      this.setState({ logo: true }, () => this.update(successCallback));
    };

    this.props.onAddImgEquipmentType(
      this.props.patient._id,
      formData,
      updLogoField,
    );
  }

  render() {
    var disable = false;
    if (this.props.user.role === Roles.CLIENT) disable = true;

    var img = null;
    if (this.props.patient && this.props.patient.equipment_type.logo) {
      img = (
        <Alert variant="dark" className="text-center">
          <img
            src={
              APIUrl.getImgChairEquipmentType +
              this.props.patient._id +
              "/" +
              Math.random() +
              "?token=" +
              APIUrl.jwtToken
            }
            width="200"
            height="200"
            alt="logo"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "/images/placeholder_120x120.png";
            }}
          />
        </Alert>
      );
    }

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-12 col-lg-8">
            <div className="form-group row">
              <label htmlFor="show" className="col-12 col-sm-5 col-form-label">
                <FormattedMessage id="Show.Equipment.Type" />
              </label>
              <div className="col-12 col-sm-7 d-flex align-items-center">
                <div className="custom-control custom-switch mx-auto d-inline-block">
                  <input
                    onChange={(e) => {
                      this.onChange("show");
                    }}
                    type="checkbox"
                    className={"custom-control-input switch-bg-blue"}
                    id={"switch-val"}
                    checked={this.state.show}
                    disabled={disable}
                  />
                  <label
                    className="custom-control-label cursor-pointer"
                    htmlFor={"switch-val"}
                  ></label>
                </div>
              </div>
            </div>

            {this.state.show && (
              <div>
                <div className="form-group row">
                  <label
                    htmlFor="commercialSign"
                    className="col-12 col-md-5 col-form-label"
                  >
                    <FormattedMessage id="Brand" />
                  </label>
                  <div className="col-12 col-md-7">
                    <input
                      type="text"
                      className="form-control"
                      id="commercialSign"
                      value={this.state.brand}
                      onChange={(e) => this.onChange("brand", e.target.value)}
                      onBlur={(e) => this.update()}
                      disabled={disable}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="contactInformation"
                    className="col-12 col-md-5 col-form-label"
                  >
                    <FormattedMessage id="Model" />
                  </label>
                  <div className="col-12 col-md-7">
                    <textarea
                      rows="3"
                      className="form-control"
                      type="text"
                      id="contactInformation"
                      name="contactInformation"
                      value={this.state.model}
                      onChange={(e) => this.onChange("model", e.target.value)}
                      onBlur={(e) => this.update()}
                      disabled={disable}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="nbr_serial"
                    className="col-12 col-md-5 col-form-label"
                  >
                    <FormattedMessage id="Serial.Number" />
                  </label>
                  <div className="col-12 col-md-7">
                    <input
                      type="text"
                      className="form-control"
                      id="nbr_serial"
                      value={this.state.nbr_serial}
                      onChange={(e) =>
                        this.onChange("nbr_serial", e.target.value)
                      }
                      onBlur={(e) => this.update()}
                      disabled={disable}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="acquisition"
                    className="col-12 col-md-5 col-form-label"
                  >
                    <FormattedMessage id="Year.acquisition" />
                  </label>
                  <div className="col-12 col-md-7">
                    <input
                      type="text"
                      className="form-control"
                      id="acquisition"
                      value={this.state.acquisition}
                      onChange={(e) =>
                        this.onChange("acquisition", e.target.value)
                      }
                      onBlur={(e) => this.update()}
                      disabled={disable}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="accessory"
                    className="col-12 col-md-5 col-form-label"
                  >
                    <FormattedMessage id="Accessory" />
                  </label>
                  <div className="col-12 col-md-7">
                    <input
                      type="text"
                      className="form-control"
                      id="accessory"
                      value={this.state.accessory}
                      onChange={(e) =>
                        this.onChange("accessory", e.target.value)
                      }
                      onBlur={(e) => this.update()}
                      disabled={disable}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="logo"
                    className="col-12 col-md-5 col-form-label"
                  >
                    <FormattedMessage id="Picture.Chair" />
                  </label>
                  <div className="col-12 col-md-7">
                    <FileDropZone
                      disabled={this.state.disabledDropZone || disable}
                      onDropFile={(file, clearCallback) =>
                        this.onDropFile(file, clearCallback)
                      }
                      acceptedExtensions={["jpg", "png"]}
                      multiple={false}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-12 col-md-5 col-form-label"></div>
                  <div className="col-12 col-md-7">{img}</div>
                </div>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    patients: state.patients,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateEquipmentType: (data, cbk) =>
      dispatch(updateEquipmentType(data, cbk)),
    onAddImgEquipmentType: (patientId, data, successCallback) =>
      dispatch(addImgEquipmentType(patientId, data, successCallback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentType);
