import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import ConfirmationModal from "../../../../sub/modals/ConfirmationModal.js";
import ModelsAdminRow from "./ModelsAdminRow";
import ErrorModal from "../../../../sub/modals/ErrorModal.js";
import Paginator from "../../../../sub/Paginator";
import Util from "../../../../../util/Util.js";
import CustomLabel from "../../../../sub/CustomLabel.js";
import ModelsAdminModal from "./ModelsAdminModal.js";
import { Alert } from "react-bootstrap";
import TableToolbar from "../../../../sub/bootstrap/TableToolbar";
import MenuButton from "../../../../sub/bootstrap/MenuButton";

class ModelsAdminTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
      brandFilter: "",
      modelFilter: "",
    };
    this.paginator = new Paginator(this);
  }

  getBrandName(brandId) {
    for (let brand of this.props.brands) {
      if (brand._id === brandId) return brand.name.toString().toUpperCase();
    }
  }

  openConfModal(title, content, successCallback) {
    this.setState({
      modal: (
        <ConfirmationModal
          isOpen={true}
          title={title}
          content={content}
          context="danger"
          successCallback={successCallback}
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  openModelsModal() {
    this.setState({
      modal: (
        <ModelsAdminModal isOpen={true} closeModal={() => this.closeModal()} />
      ),
    });
  }

  openEditModal(model) {
    this.setState({
      modal: (
        <ModelsAdminModal
          isOpen={true}
          closeModal={() => this.closeModal()}
          modelId={model._id}
          brandId={model.brand_id}
        />
      ),
    });
  }

  openErrorModal(title, content) {
    this.setState({
      modal: (
        <ErrorModal
          size="modal-xl"
          isOpen={true}
          title={title}
          content={content}
          closeModal={() => this.closeModal()}
          buttonLabel={<FormattedMessage id="Cancel" />}
        />
      ),
    });
  }

  closeModal() {
    this.setState({ modal: null });
  }

  areResultsFiltered = () => {
    if (
      !Util.emptyString(this.state.brandFilter) ||
      !Util.emptyString(this.state.modelFilter)
    ) {
      return true;
    } else {
      return false;
    }
  };

  resetSearchFields() {
    this.setState({
      brandFilter: "",
      modelFilter: "",
    });
  }

  render() {
    let { models, brands, patients, intl } = this.props;

    this.paginator.init();
    let disableFormInput = this.paginator.paginationIndex !== 1 ? true : false;

    let ModelsRowNode = models.map((model) => {
      if (this.state.modelFilter && this.state.modelFilter !== "") {
        let modelName = model.name.toLowerCase();
        if (modelName.indexOf(this.state.modelFilter.toLowerCase()) === -1)
          return null;
      }

      if (this.state.brandFilter && this.state.brandFilter !== "") {
        if (this.getBrandName(model.brand_id)) {
          let brandName = this.getBrandName(model.brand_id).toLowerCase();
          if (brandName.indexOf(this.state.brandFilter.toLowerCase()) === -1)
            return null;
        }
      }

      if (!this.paginator.keep()) return null;

      return (
        <ModelsAdminRow
          key={model._id}
          model={model}
          brands={brands}
          models={models}
          patients={patients}
          openConfModal={(title, content, successCallback) =>
            this.openConfModal(title, content, successCallback)
          }
          openErrorModal={(title, content) =>
            this.openErrorModal(title, content)
          }
          openEditModal={(model) => this.openEditModal(model)}
        />
      );
    });

    let resetSearchButton = () => {
      if (this.areResultsFiltered() && !disableFormInput) {
        return (
          <MenuButton
            icon="filter"
            onClick={() => this.resetSearchFields()}
            variant="warning"
            hover={<FormattedMessage id="Remove.Filter" />}
          />
        );
      } else {
        return (
          <MenuButton
            icon="filter"
            onClick={() => this.resetSearchFields()}
            variant="outline-secondary"
            disabled
          />
        );
      }
    };

    return (
      <div className="mt-3">
        {this.state.modal}
        {brands.length > 0 ? (
          <div>
            <TableToolbar>
              {models.length > 0 && (
                <div className="form-inline">
                  <CustomLabel
                    label={intl.formatMessage({ id: "Model" })}
                    htmlFor="search_model"
                    labelClassName="my-1 mr-2"
                  />
                  <input
                    id="search_model"
                    className="form-control mr-sm-3"
                    onChange={(e) => {
                      this.setState({ modelFilter: e.target.value });
                    }}
                    disabled={disableFormInput}
                    value={this.state.modelFilter}
                  />
                  <CustomLabel
                    label={intl.formatMessage({
                      id: "Brand.Associate",
                    })}
                    htmlFor="search_brand_model"
                    labelClassName="my-1 mr-2"
                  />
                  <input
                    id="search_brand_model"
                    className="form-control mr-sm-3"
                    onChange={(e) => {
                      this.setState({ brandFilter: e.target.value });
                    }}
                    disabled={disableFormInput}
                    value={this.state.brandFilter}
                  />

                  {resetSearchButton()}
                </div>
              )}
              <button
                className="btn btn-info ml-auto"
                onClick={(e) => this.openModelsModal()}
              >
                <FormattedMessage id="Add.Model" />
              </button>
            </TableToolbar>
            {models.length > 0 && (
              <table className="table tablee4vhp">
                <thead>
                  <tr className="d-flex">
                    <th scope="col" className="col col-2">
                      <FormattedMessage id="Model" />
                    </th>
                    <th scope="col" className="col">
                      <FormattedMessage id="Model" />
                    </th>
                    <th scope="col" className="col">
                      <FormattedMessage id="Brand.Associate" />
                    </th>
                    <th scope="col" className="text-center col col-1">
                      <FormattedMessage id="Actions" />
                    </th>
                  </tr>
                </thead>
                <tbody>{ModelsRowNode}</tbody>
              </table>
            )}
          </div>
        ) : (
          <Alert variant="secondary">
            <FormattedMessage id="Model.Add.Warning" />
          </Alert>
        )}

        <div className="mt-3">{this.paginator.render()}</div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    //
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(ModelsAdminTable));
