import React from "react";
import { connect } from "react-redux";
import { removeCollaboratorEstablishment } from "../../../../actions/collaborators/collaborators";

class CollaboratorAssignmentsRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      disabled: false,
    };
  }

  removeEstablishment(establishmentId) {
    if (this.state.disabled) return;

    this.setState({ disabled: true });

    var data = {
      collaboratorId: this.props.collaborator._id,
      establishmentId: establishmentId,
    };

    var successCallback = () => {
      this.setState({ disabled: false });
    };

    this.props.onRemoveCollaboratorEstablishment(data, successCallback);
  }

  render() {
    return (
      <tr key={this.props.establishment._id} className="tre4vhp">
        <td>{this.props.establishment.name}</td>
        <td className="d-none d-md-table-cell">
          {this.props.establishment.address}
        </td>
        <td className="text-center">
          <i
            className="fa fa-minus-circle icon-big"
            onClick={() =>
              this.removeEstablishment(this.props.establishment._id)
            }
            disabled={this.state.disabled}
          ></i>
        </td>
      </tr>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    establishments: state.establishments,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onRemoveCollaboratorEstablishment: (data, successCallback) =>
      dispatch(removeCollaboratorEstablishment(data, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CollaboratorAssignmentsRow);
