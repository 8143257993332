import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import Util from "../../../util/Util";
import { addPatientSAVFile, updPatientSAV } from "../../../actions/savs/savs";
import FileDropZone from "../../sub/FileDropZone";
import { Modal } from "react-bootstrap";

class AddPatientSAVDocumentModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ref_facture: "",
      montant_TTC: "",
      file: null,
      disabled: false,

      fileError: false,
    };
  }

  onChange(field, value) {
    this.setState({ [field]: value });
  }

  disabled() {
    return (
      Util.emptyString(this.state.ref_facture) ||
      Util.emptyString(this.state.montant_TTC) ||
      !this.state.file ||
      this.state.disabled ||
      this.state.fileError
    );
  }

  onDropFile(file) {
    this.setState({ file: file });

    if (this.props.sav.documents.find((d) => d.file_name === file.name)) {
      this.setState({
        fileError: (
          <FormattedMessage
            id="File.Already.Exists"
            values={{ fileName: file.name }}
          />
        ),
      });
    } else {
      this.setState({ fileError: false });
    }
  }

  onSubmit() {
    if (this.disabled()) return;

    const { patient, sav } = this.props;

    if (!patient || !sav) return;

    this.setState({ disabled: true });

    const successCallback = () => {
      var clonedSAV = Util.shallowClone(sav);

      if (!sav.documents) clonedSAV.documents = [];

      clonedSAV.documents.push({
        ref_facture: this.state.ref_facture,
        montant_TTC: this.state.montant_TTC,
        file_name: this.state.file.name,
        date: new Date(),
      });

      this.props.onUpdPatientSAV(clonedSAV, () => this.props.onClose());
    };

    let formData = new FormData();
    formData.append(this.state.file.name, this.state.file);

    this.props.onAddPatientSAVFile(patient._id, formData, successCallback);
  }

  render() {
    return (
      <div className="modal-bg show">
        <Modal
          show={true}
          onHide={() => this.props.onClose()}
          backdrop={"static"}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <FormattedMessage id="Patient.Document.Add" />
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="form-group row">
              <label
                htmlFor="ref_facture"
                className="col-12 col-sm-5 col-form-ref_facture"
              >
                <FormattedMessage id="Ref.Facture" />
              </label>
              <div className="col-12 col-sm-7">
                <input
                  className="form-control"
                  type="text"
                  id="ref_facture"
                  autoComplete="off"
                  value={this.state.ref_facture}
                  onChange={(e) => this.onChange("ref_facture", e.target.value)}
                />
              </div>
            </div>

            <div className="form-group row">
              <label
                htmlFor="montant_TTC"
                className="col-12 col-sm-5 col-form-montant_TTC"
              >
                <FormattedMessage id="Montant.TTC" />
              </label>
              <div className="col-12 col-sm-7">
                <input
                  className="form-control"
                  type="number"
                  id="montant_TTC"
                  autoComplete="off"
                  value={this.state.montant_TTC}
                  onChange={(e) => this.onChange("montant_TTC", e.target.value)}
                />
              </div>
            </div>

            <FileDropZone
              onDropFile={(file) => this.onDropFile(file)}
              acceptedExtensions={["pdf", "doc", "docx", "txt", "opt"]}
              multiple={false}
            />
            <div className="w-100 text-center">
              <small className="text-danger">{this.state.fileError}</small>
            </div>

            <div className="row mt-5">
              <div className="col-12 col-lg-6 offset-lg-3">
                <button
                  className="btn btn-info btn-block"
                  disabled={this.disabled()}
                  onClick={() => this.onSubmit()}
                >
                  <FormattedMessage id="Add" />
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    //
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdPatientSAV: (patient, successCallback) =>
      dispatch(updPatientSAV(patient, successCallback)),
    onAddPatientSAVFile: (patientId, file, successCallback) =>
      dispatch(addPatientSAVFile(patientId, file, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(AddPatientSAVDocumentModal));
