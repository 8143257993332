import APIUrl from "../../APIUrl";
import axios from "axios";

export const GET_HOME_ASSESSMENTS = "GET_HOME_ASSESSMENTS";

export function getHomeAssessmentsAction(HomeAssessments) {
  return { type: GET_HOME_ASSESSMENTS, HomeAssessments: HomeAssessments };
}

export const getHomeAssessments = function (callback) {
  return function (dispatch) {
    return axios.get(APIUrl.getHomeAssessments).then(function (response) {
      dispatch(getHomeAssessmentsAction(response.data));
      if (callback) callback();
    });
  };
};

export const getHomeAssessmentsAdmin = function (callback) {
  return function (dispatch) {
    return axios.get(APIUrl.getHomeAssessmentsAdmin).then(function (response) {
      dispatch(getHomeAssessmentsAction(response.data));
      if (callback) callback();
    });
  };
};

export const deleteHomeAssessmentPdf = function (
  patientId,
  homeAssessmentId,
  successCallback,
) {
  var data = {
    patientId: patientId,
    homeAssessmentId: homeAssessmentId,
  };
  return function (dispatch) {
    return axios
      .post(APIUrl.deleteHomeAssessmentPdf, data)
      .then(function (response) {
        dispatch(getHomeAssessmentsAdmin());
        if (successCallback) return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};
