import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import CollaboratorsModal from "./CollaboratorsModal";
import { UncontrolledTooltip } from "reactstrap";
import Paginator from "../../../sub/Paginator";
import { removeCollaborator } from "../../../../actions/collaborators/collaborators";
import CollaboratorTypes from "./collaboratorTypes/CollaboratorTypes";
import { Alert, Tab, Tabs } from "react-bootstrap";
import ConfirmationModal from "../../../sub/modals/ConfirmationModal";

class Collaborators extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
    };

    this.paginator = new Paginator(this);
  }

  openCollabsModal(collaborator) {
    var collaboratorId = collaborator ? collaborator._id : null;

    this.setState({
      modal: (
        <CollaboratorsModal
          close={() => this.closeModal()}
          collaboratorId={collaboratorId}
          onCollaboratorCreated={(collaborator) =>
            this.onCollaboratorCreated(collaborator)
          }
        />
      ),
    });
  }

  openConfModal(user) {
    let content = (
      <div>
        <p>
          <FormattedMessage id="User.Remove.Confirmation1" />
        </p>
        <p>
          <FormattedMessage
            id="User.Remove.Confirmation2"
            values={{ name: `${user.first_name} ${user.name}` }}
          />
        </p>
      </div>
    );

    this.setState({
      modal: (
        <ConfirmationModal
          context={"danger"}
          title={<FormattedMessage id="Warning" />}
          mandatoryConfirmation
          content={content}
          successCallback={() =>
            this.props.onRemoveCollaborators({ collaboratorId: user._id })
          }
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  onCollaboratorCreated(collaborator) {
    this.setState({ modal: null }, () => this.openCollabsModal(collaborator));
  }

  closeModal() {
    this.setState({ modal: null });
  }

  render() {
    if (this.props.collaborators.length === 0) {
      return (
        <React.Fragment>
          <button
            className="btn btn-info m-auto hoverable"
            onClick={(e) => this.openCollabsModal()}
          >
            <FormattedMessage id="Add.Collaborator" />
          </button>

          <Alert variant={"secondary"} className="mt-3">
            <FormattedMessage id="Empty.Collaborators" />
          </Alert>

          {this.state.modal}
        </React.Fragment>
      );
    }

    this.paginator.init();
    var collaboratorsNode = this.props.collaborators
      .filter((collaborator) => collaborator._id !== this.props.user._id)
      .map((collaborator) => {
        if (!this.paginator.keep()) return null;

        var establishmentsNbr = 0;
        if (collaborator.establishments)
          establishmentsNbr = collaborator.establishments.length;

        return (
          <tr key={collaborator._id} className="tre4vhp">
            <td>{collaborator.first_name}</td>
            <td>{collaborator.name}</td>
            <td className="d-none d-md-table-cell">{establishmentsNbr}</td>

            <td className="text-center tdaction">
              <i
                id={"modify-" + collaborator._id}
                className="fa fa-edit icon-big"
                onClick={() => this.openCollabsModal(collaborator)}
              />
              <UncontrolledTooltip
                delay={{ show: 0, hide: 0 }}
                placement="top"
                target={"modify-" + collaborator._id}
              >
                <FormattedMessage id="Modify" />
              </UncontrolledTooltip>

              <i
                id={"delete-" + collaborator._id}
                className="fa fa-trash icon-big"
                onClick={() => this.openConfModal(collaborator)}
              />
              <UncontrolledTooltip
                delay={{ show: 0, hide: 0 }}
                placement="top"
                target={"delete-" + collaborator._id}
              >
                <FormattedMessage id="Delete" />
              </UncontrolledTooltip>
            </td>
          </tr>
        );
      });

    return (
      <React.Fragment>
        <Tabs
          defaultActiveKey="collaborators"
          id="tab-collaborators"
          className="mb-3"
        >
          <Tab
            eventKey="collaborators"
            title={<FormattedMessage id="Collaborators" />}
          >
            <button
              className="btn btn-info m-auto hoverable"
              onClick={(e) => this.openCollabsModal()}
            >
              <FormattedMessage id="Add.Collaborator" />
            </button>

            <table className="table table-striped col-12 col-md-8 col-lg-6 tablee4vhp mt-3">
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="First.Name" />
                  </th>
                  <th>
                    <FormattedMessage id="Name" />
                  </th>
                  <th className="d-none d-md-table-cell">
                    <FormattedMessage id="Establishments" />
                  </th>
                  <th className="text-center">
                    <FormattedMessage id="Actions" />
                  </th>
                </tr>
              </thead>
              <tbody>{collaboratorsNode}</tbody>
            </table>

            {this.paginator.render()}

            {this.state.modal}
          </Tab>

          <Tab
            eventKey="types"
            title={<FormattedMessage id="Collaborators.Types" />}
          >
            <CollaboratorTypes collaborators={this.props.collaborators} />
          </Tab>
        </Tabs>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    collaborators: state.collaborators,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onRemoveCollaborators: (data) => dispatch(removeCollaborator(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Collaborators);
