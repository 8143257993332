import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import Util from "../../util/Util";
import { updPatient } from "../../actions/patients/patients";
import APIUrl from "../../APIUrl";
import { Alert, Button, Modal } from "react-bootstrap";
import CustomLabel from "../sub/CustomLabel";
class ModelWheelchairModal extends React.Component {
  constructor(props) {
    super(props);

    const { wheelchair } = this.props;

    this.isUpdate = !!wheelchair;

    const getValue = (field) => (wheelchair ? wheelchair[field] : "");

    this.state = {
      brandId: getValue("brand_id"),
      modelId: getValue("model_id"),
      adaptation: getValue("adaptation"),
      option: getValue("option"),

      loading: false,
    };
  }

  getModel(modelId) {
    for (let model of this.props.models) {
      if (model._id === modelId) return model;
    }
  }

  onSubmit() {
    this.setState({ loading: true });

    var patient = { ...this.props.patient };

    const wheelchair = {
      brand_id: this.state.brandId,
      model_id: this.state.modelId,
      adaptation: this.state.adaptation,
      option: this.state.option,
    };

    if (!patient.wheelchairs) patient.wheelchairs = [];
    patient.wheelchairs.push(wheelchair);

    this.props.onUpdPatient(patient, this.props.close());
  }

  onChange(field, value) {
    if (field === "modelId" && !Util.emptyString(value)) {
      let model = this.getModel(value);
      this.setState({ option: model.option });
    }
    this.setState({ [field]: value });
  }

  onBlur(field, value) {
    const { patient, wheelchair } = this.props;

    if ((field === "brandId" || field === "modelId") && Util.emptyString(value))
      return null;

    if (!this.isUpdate || !patient || !wheelchair) return;

    var clonedPatient = Util.shallowClone(patient);

    for (let h of clonedPatient.wheelchairs) {
      if (h._id === wheelchair._id) {
        h.brand_id = this.state.brandId;
        h.model_id = this.state.modelId;
        h.adaptation = this.state.adaptation;
        h.option = this.state.option;
      }
    }

    // Send to BE
    this.props.onUpdPatient(clonedPatient);
  }

  disabled() {
    return (
      Util.emptyString(this.state.modelId) ||
      Util.emptyString(this.state.brandId) ||
      this.state.loading
    );
  }

  render() {
    var img = null;
    if (this.props.patient && this.props.wheelchair) {
      img = (
        <Alert variant="dark" className="col-12 text-center">
          <img
            src={
              APIUrl.getModelImg +
              this.props.wheelchair.model_id +
              "/" +
              this.props.wheelchair.model_name +
              "/" +
              Math.random() +
              "?token=" +
              APIUrl.jwtToken
            }
            width="200"
            height="200"
            alt="logo"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "/images/placeholder_120x120.png";
            }}
          />
        </Alert>
      );
    }

    return (
      <div className="modal-bg show">
        <Modal
          show={true}
          onHide={() => this.props.close()}
          backdrop={"static"}
          dialogClassName={"modal-dialog xl"}
          size={"xl"}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <FormattedMessage id="Add.Model.Wheelchair" />
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {this.props.patient && this.props.wheelchair && (
              <div className="form-group row">
                <div className="col-12 col-md-5 col-form-label"></div>
                <div className="col-12 col-md-7">{img}</div>
              </div>
            )}

            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "Brand" })}
                htmlFor="brand"
                className="col-12 col-sm-5 col-form-label"
                required
              />
              <div className="col-12 col-sm-7">
                <select
                  className="form-control selectlist"
                  value={this.state.brandId || ""}
                  onChange={(e) => this.onChange("brandId", e.target.value)}
                  onBlur={(e) => this.onBlur("brand_id", e.target.value)}
                >
                  <option value="">
                    {this.props.intl.formatMessage({
                      id: "Select.Brand",
                    })}
                  </option>
                  {this.props.brands.map((brand) => {
                    return (
                      <option key={brand._id} value={brand._id}>
                        {brand.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            {this.state.brandId && (
              <div className="form-group row">
                <CustomLabel
                  label={this.props.intl.formatMessage({ id: "Model" })}
                  htmlFor="model"
                  className="col-12 col-sm-5 col-form-label"
                  required
                />
                <div className="col-12 col-sm-7">
                  <select
                    className="form-control selectlist"
                    value={this.state.modelId || ""}
                    onChange={(e) => this.onChange("modelId", e.target.value)}
                    onBlur={(e) => this.onBlur("model_id", e.target.value)}
                  >
                    <option value="">
                      {this.props.intl.formatMessage({
                        id: "Select.Model",
                      })}
                    </option>
                    {this.props.models
                      .filter((model) => model.brand_id === this.state.brandId)
                      .map((model) => {
                        return (
                          <option key={model._id} value={model._id}>
                            {model.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
            )}

            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({
                  id: "Option.Wheelchair",
                })}
                htmlFor="option"
                className="col-12 col-sm-5 col-form-label"
              />
              <div className="col-12 col-sm-7">
                <input
                  className="form-control"
                  type="text"
                  id="option"
                  autoComplete="off"
                  value={this.state.option}
                  onChange={(e) => this.onChange("option", e.target.value)}
                  onBlur={(e) => this.onBlur("option", e.target.value)}
                />
              </div>
            </div>

            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({
                  id: "Adaptation.Wheelchair",
                })}
                htmlFor="adaptation"
                className="col-12 col-sm-5 col-form-label"
              />
              <div className="col-12 col-sm-7">
                <input
                  className="form-control"
                  type="text"
                  id="adaptation"
                  autoComplete="off"
                  value={this.state.adaptation}
                  onChange={(e) => this.onChange("adaptation", e.target.value)}
                  onBlur={(e) => this.onBlur("adaptation", e.target.value)}
                />
              </div>
            </div>
          </Modal.Body>

          {!this.isUpdate && (
            <Modal.Footer>
              <Button variant="secondary" onClick={() => this.props.close()}>
                <FormattedMessage id="Cancel" />
              </Button>
              <Button
                variant="info"
                onClick={() => this.onSubmit()}
                disabled={this.disabled()}
              >
                <FormattedMessage id="Add" />
              </Button>
            </Modal.Footer>
          )}
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    brands: state.brands,
    models: state.models,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdPatient: (patient, successCallback) =>
      dispatch(updPatient(patient, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(ModelWheelchairModal));
