import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import EqSellModes from "../../enums/EqSellModes";
import DateUtil from "../../util/DateUtil";
import { Alert, Button, Modal } from "react-bootstrap";

class EquipmentsModal extends React.Component {
  stopEvent(e) {
    // e.preventDefault();
    e.stopPropagation();
  }

  render() {
    const { patient, auditEquipments } = this.props;

    let patientEquipment = patient.equipments.map((equipment) => {
      return (
        <EquipmentRow
          key={equipment._id}
          auditEquipments={auditEquipments}
          equipment={equipment}
        />
      );
      // if (patientEquipment.audit_equipment_id === auditEquipment._id) {

      //     return patientEquipment;
      // }
    });

    return (
      <div className="modal-bg show">
        <Modal
          show={true}
          onHide={() => this.props.close()}
          backdrop={"static"}
          dialogClassName={"modal-dialog xl"}
          size={"xl"}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <FormattedMessage id="Title.Equipments" />
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {(!this.props.patient ||
              !this.props.patient.equipments ||
              this.props.patient.equipments.length <= 0) && (
              <Alert variant="secondary" className="mt-3">
                <FormattedMessage id="Empty.PrescriberPatient.Equipments" />
              </Alert>
            )}
            <table className="table tablee4vhp mt-3">
              <thead>
                {this.props.patient.equipments.length > 0 && (
                  <tr>
                    <th>
                      <FormattedMessage id="Equipment" />
                    </th>
                    <th>
                      <FormattedMessage id="Mode" />
                    </th>
                    <th>
                      <FormattedMessage id="First.Install.Date" />
                    </th>
                    <th>
                      <FormattedMessage id="Last.Prescription.Date" />
                    </th>
                    <th>
                      <FormattedMessage id="Next.Renewal.Date" />
                    </th>
                  </tr>
                )}
              </thead>
              <tbody>{patientEquipment}</tbody>
            </table>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="info" onClick={() => this.props.close()}>
              <FormattedMessage id="Ok" />
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    //
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentsModal);

class EquipmentRow extends React.Component {
  renewalDate(equipment, auditEquipment) {
    var date = new Date(equipment.last_prescription_date);

    if (equipment.mode === EqSellModes.SALE) {
      date = new Date(
        date.setFullYear(
          date.getFullYear() + parseInt(auditEquipment.purchase_renewal),
        ),
      );
    } else if (equipment.mode === EqSellModes.LEASING) {
      date = new Date(
        date.setMonth(date.getMonth() + parseInt(equipment.rental_renewal)),
      );
    }

    return DateUtil.toDate(date);
  }

  render() {
    const { equipment, auditEquipments } = this.props;
    if (!auditEquipments) return;

    const auditEquipment = auditEquipments.find(
      (auditEquipment) => auditEquipment._id === equipment.audit_equipment_id,
    );

    let renewalDate = this.renewalDate(equipment, auditEquipment);

    return (
      <tr>
        <td>{auditEquipment.name}</td>
        <td>
          {equipment.mode === 0 ? (
            <FormattedMessage id="EqSellMode.1" />
          ) : (
            <FormattedMessage id="EqSellMode.0" />
          )}
        </td>
        <td>{DateUtil.toDate(equipment.first_install_date)}</td>
        <td>{DateUtil.toDate(equipment.last_prescription_date)}</td>
        <td className="d-none d-md-table-cell mw-200 align-middle">
          {renewalDate &&
          new Date(renewalDate).getTime() < new Date().getTime() ? (
            <div className="text-warning">{renewalDate}</div>
          ) : (
            <div className="text-success">{renewalDate}</div>
          )}
        </td>
      </tr>
    );
  }
}
