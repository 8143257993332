import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import Util from "../../util/Util";
import {
  updPatientDocument,
  addPatientDocumentFile,
} from "../../actions/patients/patients";
import FileDropZone from "../sub/FileDropZone";
import PatientDocumentTypes from "../../enums/PatientDocumentTypes";
import { Modal } from "react-bootstrap";
import CustomLabel from "../sub/CustomLabel";
class AddPatientDocumentModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      label: "",
      type_id: "",
      file: null,
      disabled: false,

      fileError: false,
    };
  }

  onChange(field, value) {
    this.setState({ [field]: value });
  }

  disabled() {
    return (
      Util.emptyString(this.state.label) ||
      !this.state.type_id ||
      !this.state.file ||
      this.state.disabled ||
      this.state.fileError
    );
  }

  onDropFile(file) {
    this.setState({ file: file });

    if (this.props.patient.documents.find((p) => p.file_name === file.name)) {
      this.setState({
        fileError: (
          <FormattedMessage
            id="File.Already.Exists"
            values={{ fileName: file.name }}
          />
        ),
      });
    } else {
      this.setState({ fileError: false });
    }
  }

  onSubmit() {
    if (this.disabled()) return;

    const { patient } = this.props;

    if (!patient) return;

    this.setState({ disabled: true });

    const successCallback = () => {
      var clonedPatient = Util.shallowClone(patient);
      if (!clonedPatient.documents) clonedPatient.documents = [];

      clonedPatient.documents.push({
        title: this.state.label,
        date: Date.now(),
        file_name: this.state.file.name,
        type_id: this.state.type_id,
      });

      this.props.onUpdPatientDocument(
        clonedPatient,
        () => this.props.close(),
        this.props.user.role,
      );
    };

    let formData = new FormData();
    formData.append(this.state.file.name, this.state.file);

    this.props.onAddPatientDocumentFile(patient._id, formData, successCallback);
  }

  render() {
    //this.props.intl.formatMessage({ id: "Expenses" })
    return (
      <div className="modal-bg show">
        <Modal
          show={true}
          onHide={() => this.props.close()}
          backdrop={"static"}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <FormattedMessage id="Patient.Document.Add" />
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "Title" })}
                htmlFor="label"
                className="col-12 col-md-5 col-form-label"
                required
              />
              <div className="col-12 col-sm-7">
                <input
                  className="form-control"
                  type="text"
                  id="label"
                  autoComplete="off"
                  value={this.state.label}
                  onChange={(e) => this.onChange("label", e.target.value)}
                />
              </div>
            </div>

            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "Type" })}
                htmlFor="fleet-filter-documentType"
                className="col-12 col-md-5 col-form-label"
                required
              />
              <div className="col-12 col-sm-7">
                <select
                  id="fleet-filter-documentType"
                  className="form-control col-12 mx-auto selectlist"
                  onChange={(e) => this.onChange("type_id", e.target.value)}
                >
                  <option value=""></option>

                  <option value={PatientDocumentTypes.QUOTATION}>
                    {this.props.intl.formatMessage({
                      id:
                        "Patient.DocumentType." +
                        PatientDocumentTypes.QUOTATION,
                    })}
                  </option>

                  <option value={PatientDocumentTypes.COMMERCIAL_SHEET}>
                    {this.props.intl.formatMessage({
                      id:
                        "Patient.DocumentType." +
                        PatientDocumentTypes.COMMERCIAL_SHEET,
                    })}
                  </option>

                  <option
                    value={PatientDocumentTypes.SUPPLIER_MEASUREMENT_SHEET}
                  >
                    {this.props.intl.formatMessage({
                      id:
                        "Patient.DocumentType." +
                        PatientDocumentTypes.SUPPLIER_MEASUREMENT_SHEET,
                    })}
                  </option>

                  <option
                    value={PatientDocumentTypes.SERVICE_GUIDE_MAINTENANCE}
                  >
                    {this.props.intl.formatMessage({
                      id:
                        "Patient.DocumentType." +
                        PatientDocumentTypes.SERVICE_GUIDE_MAINTENANCE,
                    })}
                  </option>

                  <option value={PatientDocumentTypes.PRESCRIPTION}>
                    {this.props.intl.formatMessage({
                      id:
                        "Patient.DocumentType." +
                        PatientDocumentTypes.PRESCRIPTION,
                    })}
                  </option>

                  <option
                    value={PatientDocumentTypes.APPROPRIATE_ELECTRIC_WHEELCHAIR}
                  >
                    {this.props.intl.formatMessage({
                      id:
                        "Patient.DocumentType." +
                        PatientDocumentTypes.APPROPRIATE_ELECTRIC_WHEELCHAIR,
                    })}
                  </option>

                  <option value={PatientDocumentTypes.PRIOR_AGREEMENT_REQUEST}>
                    {this.props.intl.formatMessage({
                      id:
                        "Patient.DocumentType." +
                        PatientDocumentTypes.PRIOR_AGREEMENT_REQUEST,
                    })}
                  </option>

                  <option value={PatientDocumentTypes.DELIVERY_FORM}>
                    {this.props.intl.formatMessage({
                      id:
                        "Patient.DocumentType." +
                        PatientDocumentTypes.DELIVERY_FORM,
                    })}
                  </option>

                  <option value={PatientDocumentTypes.BILL}>
                    {this.props.intl.formatMessage({
                      id: "Patient.DocumentType." + PatientDocumentTypes.BILL,
                    })}
                  </option>
                </select>
              </div>
            </div>

            <FileDropZone
              onDropFile={(file) => this.onDropFile(file)}
              acceptedExtensions={[
                "pdf",
                "doc",
                "docx",
                "txt",
                "opt",
                "png",
                "jpg",
              ]}
              multiple={false}
            />

            <div className="w-100 text-center">
              <small className="text-danger">{this.state.fileError}</small>
            </div>

            <div className="row mt-5">
              <div className="col-12 col-lg-6 offset-lg-3">
                <button
                  className="btn btn-info btn-block"
                  disabled={this.disabled()}
                  onClick={() => this.onSubmit()}
                >
                  <FormattedMessage id="Add" />
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdPatientDocument: (patient, successCallback, userRole) =>
      dispatch(updPatientDocument(patient, successCallback, userRole)),
    onAddPatientDocumentFile: (patientId, file, successCallback) =>
      dispatch(addPatientDocumentFile(patientId, file, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(AddPatientDocumentModal));
