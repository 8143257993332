import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { withRouter } from "react-router-dom";
import MenuProfile from "./MenuProfile";
import { NavLink } from "react-router-dom";
import MenuLogo from "./MenuLogo";

class Menu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
    };
  }

  render() {
    return (
      <React.Fragment>
        <nav className="navbar navbar-expand-lg menu">
          <div
            id="menu"
            className="collapse navbar-collapse mb-3 mb-md-0 mt-2 pb-2"
          >
            <ul className="navbar-nav list-inline text-center align-items-center">
              <li className="nav-item list-inline-item align-middle">
                <img
                  className="logo-header"
                  alt="logo PEPSiHANDICAP"
                  src="./images/svg/logo_pepsihandicap.png"
                />
              </li>

              <li className="nav-item list-inline-item align-middle text-nowrap">
                <NavLink
                  className="w-100 p-3"
                  to="/home"
                  exact
                  activeClassName="selected"
                >
                  <FormattedMessage id="Home" />
                </NavLink>
              </li>

              <li className="nav-item list-inline-item align-middle text-nowrap">
                <NavLink
                  className="w-100 p-3"
                  to="/home/patients"
                  exact
                  activeClassName="selected"
                >
                  <FormattedMessage id="Patients.Monitoring" />
                </NavLink>
              </li>

              <li className="nav-item list-inline-item align-middle text-nowrap">
                <NavLink
                  className="w-100 p-3"
                  to="/home/calendar"
                  exact
                  activeClassName="selected"
                >
                  <FormattedMessage id="Calendar" />
                </NavLink>
              </li>

              <MenuLogo generalSettings={this.props.generalSettings} />

              <li className="menuProfile nav-item list-inline-item align-middle text-nowrap">
                <MenuProfile />
              </li>
            </ul>
          </div>

          <button
            className="navbar-toggler m-auto"
            type="button"
            data-toggle="collapse"
            data-target="#menu"
            aria-controls="menu"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        </nav>
        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    generalSettings: state.generalSettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Menu));
