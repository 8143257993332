import APIUrl from "../../APIUrl";
import axios from "axios";

export const GET_ANTHROPOMETRIC_MEASUREMENTS =
  "GET_ANTHROPOMETRIC_MEASUREMENTS";

export function getAnthropometricMeasurementAction(AnthropometricMeasurements) {
  return {
    type: GET_ANTHROPOMETRIC_MEASUREMENTS,
    AnthropometricMeasurements: AnthropometricMeasurements,
  };
}

export const getAnthropometricMeasurementsAdmin = function (callback) {
  return function (dispatch) {
    return axios
      .get(APIUrl.getAnthropometricMeasurementsAdmin)
      .then(function (response) {
        dispatch(getAnthropometricMeasurementAction(response.data));
        if (callback) callback();
      });
  };
};

export const getAnthropometricMeasurements = function (callback) {
  return function (dispatch) {
    return axios
      .get(APIUrl.getAnthropometricMeasurements)
      .then(function (response) {
        dispatch(getAnthropometricMeasurementAction(response.data));
        if (callback) callback();
      });
  };
};

export const deleteAnthropometricMeasurement = function (
  patientId,
  anthropometricMeasurementId,
  successCallback,
) {
  var data = {
    patientId: patientId,
    anthropometricMeasurementId: anthropometricMeasurementId,
  };
  return function (dispatch) {
    return axios
      .post(APIUrl.deleteAnthropometricMeasurement, data)
      .then(function (response) {
        dispatch(getAnthropometricMeasurementsAdmin());
        if (successCallback) return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};
