import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import Util from "../../../util/Util";
import { updPatient } from "../../../actions/patients/patients";
import TrialCreatePage from "./pages/TrialCreatePage";
import ConfirmationModal from "../../sub/modals/ConfirmationModal";
import { Modal } from "react-bootstrap";
// import PatientTrialTypes from '../../enums/PatientTrialTypes'

class AddPatientTrialModal extends React.Component {
  constructor(props) {
    super(props);

    var trialPerceptions = {};
    if (this.props.trialPerceptions) {
      for (var trialPerception of this.props.trialPerceptions) {
        trialPerceptions[trialPerception.name] = {
          well: false,
          medium: false,
          bad: false,
        };
      }
    }

    this.state = {
      patient_id: this.props.patient ? this.props.patient._id : "",
      date_start: "",
      date_end: "",
      loan_material: "",
      result: "",
      signature: "",
      files: null,
      status: "",
      title: "",
      pdf_exist: false,
      characteristics: trialPerceptions,
      prescriber_id: "",
      commentary: "",
      mode: "create", // WEB must have "create" ; TABLET must have "initial"
      modal: null,
      disabled: false,
      fileError: false,
    };
  }

  stopEvent(e) {
    //e.preventDefault();
    e.stopPropagation();
  }

  onChange(field, value) {
    this.setState({ [field]: value });
  }

  disabled() {
    return (
      Util.emptyString(this.state.title) ||
      !this.state.file ||
      this.state.disabled ||
      this.state.fileError
    );
  }

  close() {
    const confirm = () => this.props.closeModal();

    const title = <FormattedMessage id="Confirmation" />;
    const content = <FormattedMessage id="Really.Quit" />;

    this.setState({
      modal: (
        <ConfirmationModal
          isOpen={true}
          title={title}
          content={content}
          successCallback={confirm}
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  closeModal() {
    this.setState({ modal: null });
  }

  trialToSend(trial) {
    let trialToSend = {};

    trialToSend.patient_id = trial.patient_id;
    trialToSend.date_start = trial.date_start;
    trialToSend.date_end = trial.date_end;
    trialToSend.loan_material = trial.loan_material;
    trialToSend.characteristics = trial.characteristics;
    trialToSend.result = trial.result;
    trialToSend.signature = trial.signature;
    trialToSend.files = trial.files;
    trialToSend.status = trial.status;
    trialToSend.title = trial.title;
    trialToSend.pdf_exist = trial.pdf_exist;
    trialToSend.commentary = trial.commentary;

    return trialToSend;
  }

  render() {
    const { patient, trials } = this.props;

    return (
      <>
        <div className="modal-bg show">
          <Modal
            show={true}
            onHide={() => this.close()}
            backdrop={"static"}
            dialogClassName={
              "modal-dialog xl no-max-height height-full height-auto"
            }
            size={"xl"}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <FormattedMessage id="Patient.Trial.Add" />
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              {this.state.mode === "create" && (
                <TrialCreatePage
                  trial={this.state}
                  setState={(state) => this.setState(state)}
                  patient={patient}
                  trials={trials}
                  trialToSend={this.trialToSend}
                  closeModal={() => this.props.closeModal()}
                />
              )}
            </Modal.Body>
          </Modal>
        </div>

        {this.state.modal}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    trials: state.trials,
    trialPerceptions: state.trialPerceptions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdPatient: (patient, successCallback) =>
      dispatch(updPatient(patient, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(AddPatientTrialModal));
