import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import Roles from "../../enums/Roles";
import { FormattedMessage } from "react-intl";
import { UncontrolledTooltip } from "reactstrap";

import "../../css/orders/orders.css";

class PatientRow extends React.Component {
  getTherapistPatient() {
    const { patient } = this.props;
    const patientMainTherapistId =
      patient.main_prescriber_id || patient.prescribers_ids[0];

    let therapist = this.props.clients.find(
      (client) => client._id === patientMainTherapistId,
    );
    if (!therapist || therapist.length === 0) return null;
    return therapist.name + " " + therapist.first_name;
  }

  getType() {
    const { patient } = this.props;
    const patientId = patient._id;

    let expressionType = this.props.expressionNeeds.find(
      (x) => x.patient_id === patientId,
    );
    if (!expressionType || expressionType.length === 0) return null;
    return expressionType.type;
  }

  getNewTrial() {
    const { patient } = this.props;
    const patientId = patient._id;

    if (
      this.props.trials.find(
        (x) => x.pdf_exist === false && x.patient_id === patientId,
      )
    )
      return true;
    else return false;
  }

  render() {
    const { user, patient, establishments } = this.props;
    if (!user || !patient || !establishments) return null;

    var establishment = null;
    if (user.role === Roles.CLIENT) establishment = this.props.establishment;
    else
      establishment = establishments.find(
        (e) => e._id === patient.establishment_id,
      );

    if (!establishment) return null;
    return (
      <React.Fragment>
        <tr key={patient._id} className="d-flex align-items-center h-100">
          <td className="col">{patient.name + " " + patient.first_name}</td>
          {this.props.user.role !== Roles.CLIENT && (
            <td className="col">{establishment.name}</td>
          )}
          {this.props.user.role !== Roles.CLIENT && (
            <td className="col">{this.getTherapistPatient()}</td>
          )}
          <td className="col"> {this.getType()}</td>
          <td className="col text-center">
            <i
              id={"display-expression-needs-" + patient._id}
              className="fa fa-eye icon-big"
              onClick={() => this.props.openModal("expression-needs")}
            ></i>
            <UncontrolledTooltip
              delay={{ show: 0, hide: 0 }}
              placement="top"
              target={"display-expression-needs-" + patient._id}
            >
              <FormattedMessage id="Display" />
            </UncontrolledTooltip>
          </td>
          <td className="col text-center">
            <i
              id={"display-anthropometric-measurement-" + patient._id}
              className="fa fa-eye icon-big"
              onClick={() => this.props.openModal("anthropometric-measurement")}
            ></i>
            <UncontrolledTooltip
              delay={{ show: 0, hide: 0 }}
              placement="top"
              target={"display-anthropometric-measurement-" + patient._id}
            >
              <FormattedMessage id="Display" />
            </UncontrolledTooltip>
          </td>
          <td className="col text-center">
            <i
              id={"display-trials-" + patient._id}
              className="fa fa-eye icon-big"
              onClick={() => this.props.openModal("trials")}
            ></i>
            <UncontrolledTooltip
              delay={{ show: 0, hide: 0 }}
              placement="top"
              target={"display-trials-" + patient._id}
            >
              <FormattedMessage id="Display" />
            </UncontrolledTooltip>
          </td>
          <td className="col text-center">
            <i
              id={"display-documents-" + patient._id}
              className="fa fa-eye icon-big"
              onClick={() => this.props.openModal("documents")}
            ></i>
            <UncontrolledTooltip
              delay={{ show: 0, hide: 0 }}
              placement="top"
              target={"display-documents-" + patient._id}
            >
              <FormattedMessage id="Display" />
            </UncontrolledTooltip>
          </td>
          <td className="col text-center">
            <i
              id={"display-sav-" + patient._id}
              className="fa fa-eye icon-big"
              onClick={() => this.props.openModal("sav")}
            ></i>
            <UncontrolledTooltip
              delay={{ show: 0, hide: 0 }}
              placement="top"
              target={"display-sav-" + patient._id}
            >
              <FormattedMessage id="Display" />
            </UncontrolledTooltip>
          </td>
          <td className="col text-center">
            <div className="custom-control custom-switch mx-auto d-inline-block">
              <input
                type="checkbox"
                className={"custom-control-input switch-bg-blue"}
                id={"switch-av" + patient._id}
                readOnly={true}
                checked={this.getNewTrial()}
              />
              <label
                className="custom-control-label cursor-pointer"
                htmlFor={"switch-av" + patient._id}
              ></label>
            </div>
          </td>

          {!this.props.limit &&
            (this.props.user.role === Roles.ADMIN ||
              this.props.user.role === Roles.SALES_REP) && (
              <td className="col text-center tdaction">
                <i
                  id={"modify-" + patient._id}
                  className="fa fa-edit icon-big"
                  onClick={(e) => this.props.edit(patient)}
                ></i>
                <UncontrolledTooltip
                  delay={{ show: 0, hide: 0 }}
                  placement="top"
                  target={"modify-" + patient._id}
                >
                  <FormattedMessage id="Modify" />
                </UncontrolledTooltip>
                <i
                  id={"delete-" + patient._id}
                  className="fa fa-trash icon-big"
                  onClick={(e) => this.props.delete(patient)}
                ></i>
                <UncontrolledTooltip
                  delay={{ show: 0, hide: 0 }}
                  placement="top"
                  target={"delete-" + patient._id}
                >
                  <FormattedMessage id="Delete" />
                </UncontrolledTooltip>
              </td>
            )}

          {!this.props.limit && this.props.user.role === Roles.CLIENT && (
            <td className="col text-center tdaction">
              <i
                id={"consult-" + patient._id}
                className="fa fa-eye icon-big"
                onClick={(e) => this.props.edit(patient)}
              ></i>
            </td>
          )}
          {!this.props.limit && this.props.user.role === Roles.CLIENT && (
            <UncontrolledTooltip
              delay={{ show: 0, hide: 0 }}
              placement="top"
              target={"consult-" + patient._id}
            >
              <FormattedMessage id="Consult.Patient" />
            </UncontrolledTooltip>
          )}
        </tr>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    patients: state.patients,
    user: state.user,
    clients: state.clients,
    establishment: state.establishment,
    establishments: state.establishments,
    expressionNeeds: state.expressionNeeds,
    trials: state.trials,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(PatientRow));
