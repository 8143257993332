import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import Util from "../../../util/Util";
import { addPatientSAV } from "../../../actions/savs/savs";
import DateUtil from "../../../util/DateUtil";
import { Button, Modal } from "react-bootstrap";
import CustomLabel from "../../sub/CustomLabel";
class AddPatientSAVForfaitModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      start_date: DateUtil.toyyyyMMdd(new Date()),
      bundle_id: "",
      loading: false,
    };
  }

  stopEvent(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  onChange(field, value) {
    this.setState({ [field]: value });
  }

  disabled() {
    return (
      this.state.loading ||
      !this.state.start_date ||
      Util.emptyString(this.state.start_date) ||
      !this.state.bundle_id ||
      Util.emptyString(this.state.bundle_id)
    );
  }

  onSubmit() {
    if (this.disabled()) return;

    const { patient } = this.props;

    this.setState({ loading: true });

    const forfait = {
      patient_id: patient._id,
      start_date: this.state.start_date,
      bundle_id: this.state.bundle_id,
    };

    this.props.onAddPatientSAVForfait(forfait, () => this.props.onClose());
  }

  render() {
    const { patient, bundles } = this.props;

    if (!patient) return null;

    var bundlesNode = bundles.map((b) => (
      <option value={b._id} key={b._id}>
        {b.select_bundle_SAV}
      </option>
    ));

    return (
      <div className="modal-bg show">
        <Modal
          show={true}
          onHide={() => this.props.onClose()}
          backdrop={"static"}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <FormattedMessage id="Patient.SAV.Forfait.Add" />
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "Designation" })}
                htmlFor="bundle_id"
                className="col-12 col-sm-5 col-form-label"
                required
              />
              <div className="col-12 col-md-7" style={{ fontSize: "20px" }}>
                <select
                  id="bundle_id"
                  className="form-control col-12 mx-auto selectlist"
                  onChange={(e) => this.onChange("bundle_id", e.target.value)}
                >
                  <option value=""></option>;{bundlesNode}
                </select>
              </div>
            </div>

            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "Start.Date" })}
                htmlFor="start_date"
                className="col-12 col-sm-5 col-form-label"
                required
              />
              <div className="col-12 col-sm-7">
                <input
                  className="form-control"
                  type="date"
                  id="start_date"
                  autoComplete="off"
                  value={this.state.start_date}
                  onChange={(e) => this.onChange("start_date", e.target.value)}
                />
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.props.onClose()}>
              <FormattedMessage id="Cancel" />
            </Button>
            <Button
              variant="info"
              onClick={() => this.onSubmit()}
              disabled={this.disabled()}
            >
              <FormattedMessage id="Add" />
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    bundles: state.bundles,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddPatientSAVForfait: (forfait, successCallback) =>
      dispatch(addPatientSAV(forfait, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(AddPatientSAVForfaitModal));
