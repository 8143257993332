import React from "react";
import { connect } from "react-redux";
import DateUtil from "../../util/DateUtil";
import { FormattedMessage, injectIntl } from "react-intl";
import APIUrl from "../../APIUrl";
import { Alert, Modal } from "react-bootstrap";

class ElementsPdfsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
    };

    this.title = <FormattedMessage id="Elements.PDFS.Title" />;
  }

  stopEvent(e) {
    e.stopPropagation();
  }

  auditsPatient() {
    let auditsPatient = [];
    for (var audit of this.props.audits) {
      if (audit.patient_id === this.props.patient._id) {
        auditsPatient.push(audit);
      }
    }
    return auditsPatient;
  }

  render() {
    let auditsPatient = this.auditsPatient();
    let fileNameAudit =
      "audit_" +
      this.props.patient.first_name +
      "_" +
      this.props.patient.name +
      "_";

    return (
      <React.Fragment>
        <div className="modal-bg show">
          <Modal
            show={true}
            onHide={() => this.props.close()}
            backdrop={"static"}
            dialogClassName={"modal-dialog xl"}
            size={"xl"}
          >
            <Modal.Header closeButton>
              <Modal.Title>{this.title}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              {(!this.props.patient ||
                !this.props.audits ||
                auditsPatient <= 0) && (
                <Alert variant="secondary" className="mt-3">
                  <FormattedMessage id="Empty.ElementsPdfs" />
                </Alert>
              )}

              {this.props.patient &&
                auditsPatient &&
                auditsPatient.length > 0 && (
                  <table className="table tablee4vhp">
                    <thead>
                      <tr className="tablerowhead">
                        <th className="d-md-table-cell">
                          <FormattedMessage id="Creation.Date" />{" "}
                          <i className="fa fa-chevron-down float-right mr-5"></i>
                        </th>
                        <th className="d-md-table-cell">
                          <FormattedMessage id="Actions" />{" "}
                          <i className="fa fa-chevron-down float-right mr-5"></i>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {auditsPatient.map((p) => {
                        return (
                          <tr key={p._id} className={"order-tr order-tr"}>
                            <td className="d-md-table-cell mw-200 align-middle">
                              {DateUtil.toDate(p.createdAt)}
                            </td>
                            <td className="d-md-table-cell mw-200 text-center">
                              <div key={p._id}>
                                <a
                                  role="button"
                                  href={
                                    APIUrl.getAuditsPDF +
                                    p.patient_id +
                                    "/" +
                                    p._id +
                                    "?token=" +
                                    APIUrl.jwtToken
                                  }
                                  download={
                                    fileNameAudit +
                                    DateUtil.toyyyyMMdd(p.createdAt) +
                                    ".pdf"
                                  }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <i
                                    className="fa fa-download icon-big text-dark"
                                    disabled=""
                                  ></i>
                                </a>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
            </Modal.Body>
          </Modal>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    //
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(ElementsPdfsModal));
