import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import Roles from "../../enums/Roles";
import CalendarEventsStatistics from "./CalendarEventsStatistics";
import {
  getCalendarEvents,
  getCalendarEventsAdmin,
} from "../../actions/calendarEvents/calendarEvents";
import { Tab, Tabs } from "react-bootstrap";

class Statistics extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collaboratorId: null,
      establishmentId: null,
    };
  }

  selectEstablishment(establishmentId) {
    this.setState({ establishmentId: establishmentId });
  }

  selectCollaborator(collaboratorId) {
    this.setState({ collaboratorId: collaboratorId });
  }

  getCollaborator(collaboratorId) {
    for (let collaborator of this.props.collaborators) {
      if (collaborator._id === collaboratorId) return collaborator;
    }
  }

  getEvents(collaboratorId, establishmentId) {
    let events = [];

    for (const event of this.props.calendarEvents) {
      if (event.collaborator_id && collaboratorId) {
        const collaborator = this.getCollaborator(event.collaborator_id);
        if (
          collaboratorId &&
          collaborator &&
          collaborator._id === collaboratorId
        )
          events.push(event);
      }
    }

    return events;
  }

  componentDidMount() {
    if (this.props.calendarEvents.length === 0) {
      if (
        this.props.user.role === Roles.ADMIN ||
        this.props.user.role === Roles.SALES_REP
      )
        this.props.onGetCalendarEventsAdmin();
      else this.props.onGetCalendarEvents();
    }
  }

  render() {
    var collaboratorsNode = this.props.collaborators.map((collaborator) => {
      if (collaborator.role === Roles.SALES_REP) {
        return (
          <option key={collaborator._id} value={collaborator._id}>
            {collaborator.name}
          </option>
        );
      } else return null;
    });

    var establishmentsNode = this.props.establishments.map((establishment) => {
      if (this.state.collaboratorId) {
        let collaborator = this.props.collaborators.map((collaborator) => {
          if (
            collaborator._id === this.state.collaboratorId &&
            collaborator.establishments &&
            collaborator.establishments.find(
              (c) => c.establishment_id === establishment._id,
            )
          )
            return (
              <option key={establishment._id} value={establishment._id}>
                {establishment.name}
              </option>
            );
          return null;
        });
        return collaborator;
      }
      return null;
    });

    var collaboratorSelectNode = (
      <div className="text-center col-3 offset-3" role="alert">
        <select
          id="fleet-filter-collaboratorId"
          className="form-control mx-auto selectlist"
          onChange={(e) => this.selectCollaborator(e.target.value)}
        >
          <option value="">
            {this.props.intl.formatMessage({ id: "Select.Collaborator" })}...
          </option>
          {collaboratorsNode}
        </select>
      </div>
    );

    var establishmentsSelectNode = (
      <div className="text-center col-3" role="alert">
        <select
          id="fleet-filter-establishmentId"
          className="form-control mx-auto selectlist"
          onChange={(e) => this.selectEstablishment(e.target.value)}
        >
          <option value="">
            {this.props.intl.formatMessage({ id: "Select.Establishment" })}...
          </option>
          {establishmentsNode}
        </select>
      </div>
    );

    var events = this.getEvents(
      this.state.collaboratorId,
      this.state.establishmentId,
    );

    return (
      <React.Fragment>
        <div className="d-flex align-items-center">
          {collaboratorSelectNode} {establishmentsSelectNode}
        </div>
        <Tabs defaultActiveKey="events" id="tab-stats" className="mb-3">
          <Tab eventKey="events" title={<FormattedMessage id="Statistics" />}>
            <CalendarEventsStatistics
              events={events}
              collaborators={this.props.collaborators}
              establishmentId={this.state.establishmentId}
            />
          </Tab>
        </Tabs>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    establishments: state.establishments,
    calendarEvents: state.calendarEvents,
    collaborators: state.collaborators,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetCalendarEventsAdmin: () => dispatch(getCalendarEventsAdmin()),
    onGetCalendarEvents: () => dispatch(getCalendarEvents()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(Statistics));
