import APIUrl from "../../APIUrl";
import axios from "axios";

export const GET_CALENDAR_EVENTS = "GET_CALENDAR_EVENTS";

function getCalendarEventsAction(calendarEvents) {
  return { type: GET_CALENDAR_EVENTS, calendarEvents: calendarEvents };
}

/**
 * ADMIN ACTIONS
 */

// GET EVENTS
export const getCalendarEventsAdmin = function () {
  return function (dispatch) {
    return axios
      .get(APIUrl.getCalendarEventsAdmin)
      .then(function (response) {
        dispatch(getCalendarEventsAction(response.data));
      })
      .catch(function (err) {
        throw err;
      });
  };
};

// ADD EVENT
export const addCalendarEventAdmin = function (calendarEvent, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addCalendarEventAdmin, calendarEvent)
      .then(function (response) {
        dispatch(getCalendarEventsAdmin());
        if (successCallback) successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

// UPDATE EVENT
export const updateCalendarEventAdmin = function (data) {
  return function (dispatch) {
    return axios
      .post(APIUrl.updCalendarEventAdmin, data)
      .then(function (response) {
        dispatch(getCalendarEventsAdmin());
      })
      .catch(function (err) {
        throw err;
      });
  };
};

// DELETE EVENT
export const deleteCalendarEventAdmin = function (
  calendarEventId,
  successCallback,
) {
  return function (dispatch) {
    return axios
      .post(APIUrl.deleteCalendarEventAdmin, { calendarEventId })
      .then(function (response) {
        dispatch(getCalendarEventsAdmin());
        if (successCallback) successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

/**
 * CLIENT ACTIONS
 */

// GET EVENTS
export const getCalendarEvents = function () {
  return function (dispatch) {
    return axios
      .get(APIUrl.getCalendarEvents)
      .then(function (response) {
        if (response) dispatch(getCalendarEventsAction(response.data));
      })
      .catch(function (err) {
        throw err;
      });
  };
};

// ADD EVENT
export const addCalendarEventClient = function (
  calendarEvent,
  successCallback,
) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addCalendarEventClient, calendarEvent)
      .then(function (response) {
        dispatch(getCalendarEvents());
        if (successCallback) successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

// DELETE EVENT
export const deleteCalendarEventClient = function (
  calendarEventId,
  successCallback,
) {
  return function (dispatch) {
    return axios
      .post(APIUrl.deleteCalendarEventClient, { calendarEventId })
      .then(function (response) {
        dispatch(getCalendarEvents());
        if (successCallback) successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};
