import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { updPatient } from "../../actions/patients/patients";
import { deleteAnthropometricMeasurement } from "../../actions/anthropometricMeasurement/anthropometricMeasurement";
import DateUtil from "../../util/DateUtil";
import APIUrl from "../../APIUrl";
import ConfirmationModal from "../sub/modals/ConfirmationModal";
import "../../css/anthropometric/anthropometricMeasurement.css";
import Roles from "../../enums/Roles";
import { UncontrolledTooltip } from "reactstrap";
import { Alert } from "react-bootstrap";

class PatientAnthropometricMeasurement extends React.Component {
  constructor(props) {
    super(props);

    const { patient } = this.props;

    const getValue = (field) => {
      let res;
      if (patient) {
        if (patient[field]) res = patient[field];
      }
      return res;
    };

    this.state = {
      measurements: getValue("measurements"),
      modal: null,
      modalConfirmation: null,
    };
  }

  closeModal() {
    this.setState({ modalConfirmation: null });
  }

  delete(anthropometricMeasurement) {
    const { patient } = this.props;

    if (!anthropometricMeasurement || !patient) return;

    this.setState({
      modalConfirmation: (
        <ConfirmationModal
          isOpen={true}
          title={<FormattedMessage id="Confirm" />}
          content={<FormattedMessage id="Confirm.Pdf.Removal" />}
          successCallback={() =>
            this.props.onDeleteAnthropometricMeasurement(
              patient._id,
              anthropometricMeasurement._id,
            )
          }
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  render() {
    const patientId = this.props.patient._id;
    const patientAnthropometricMeasurement =
      this.props.anthropometricMeasurement.filter(
        (x) => x.patient_id === patientId,
      );
    return (
      <React.Fragment>
        {(!patientAnthropometricMeasurement ||
          patientAnthropometricMeasurement.length <= 0) && (
          <Alert variant="secondary" className="mt-3">
            <FormattedMessage id="Empty.Pdf" />
          </Alert>
        )}
        {patientAnthropometricMeasurement.length > 0 && (
          <table className="table tablee4vhp mt-3">
            <thead>
              <tr className="tablerowhead">
                <th className="d-md-table-cell">
                  <FormattedMessage id="Date" />{" "}
                  <i className="fa fa-chevron-down float-right mr-5"></i>
                </th>
                <th className="d-md-table-cell">
                  <FormattedMessage id="Title" />{" "}
                  <i className="fa fa-chevron-down float-right mr-5"></i>
                </th>
                <th className="d-none d-md-table-cell">
                  <FormattedMessage id="Actions" />{" "}
                  <i className="fa fa-chevron-down float-right mr-5"></i>
                </th>
              </tr>
            </thead>
            <tbody>
              {patientAnthropometricMeasurement.map((x, index) => (
                <tr key={index} className={"order-tr order-tr"}>
                  <td className="d-md-table-cell mw-200 align-middle">
                    {DateUtil.toDate(x.createdAt)}
                  </td>
                  <td className="d-md-table-cell mw-200 align-middle">
                    {x.title_pdf}
                  </td>

                  <td className="d-md-table-cell mw-200 align-middle tdaction">
                    <a
                      role="button"
                      href={
                        APIUrl.getAnthropometricMeasurementPdf +
                        this.props.patient._id +
                        "/" +
                        x._id +
                        "?token=" +
                        APIUrl.jwtToken
                      }
                      rel="noopener noreferrer"
                      download={
                        x.title_pdf
                          ? x.title_pdf + ".pdf"
                          : "prise_de_mesure_anthropometrique.pdf"
                      }
                      target="_blank"
                    >
                      <i
                        id={"display-" + x._id}
                        className="fa fa-download icon-big"
                      ></i>
                      <UncontrolledTooltip
                        delay={{ show: 0, hide: 0 }}
                        placement="top"
                        target={"display-" + x._id}
                      >
                        <FormattedMessage id="Display.PDF" />
                      </UncontrolledTooltip>
                    </a>
                    {(this.props.user.role === Roles.ADMIN ||
                      this.props.user.role === Roles.SALES_REP) && (
                      <i
                        id={"delete-" + x._id}
                        className="fa fa-trash icon-big"
                        onClick={(e) => this.delete(x)}
                      ></i>
                    )}
                    {(this.props.user.role === Roles.ADMIN ||
                      this.props.user.role === Roles.SALES_REP) && (
                      <UncontrolledTooltip
                        delay={{ show: 0, hide: 0 }}
                        placement="top"
                        target={"delete-" + x._id}
                      >
                        <FormattedMessage id="Delete" />
                      </UncontrolledTooltip>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        {this.state.modalConfirmation}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    anthropometricMeasurement: state.anthropometricMeasurement,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdPatient: (patient, successCallback) =>
      dispatch(updPatient(patient, successCallback)),
    onDeleteAnthropometricMeasurement: (
      patientId,
      anthropometricMeasurementId,
      successCallback,
    ) =>
      dispatch(
        deleteAnthropometricMeasurement(
          patientId,
          anthropometricMeasurementId,
          successCallback,
        ),
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(PatientAnthropometricMeasurement));
