import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import Util from "../../util/Util";
import {
  updHomeAssessment,
  addHomeAssessmentDocumentFile,
} from "../../actions/patients/patients";
import FileDropZone from "../sub/FileDropZone";
import { Modal } from "react-bootstrap";
import CustomLabel from "../sub/CustomLabel";
class AddHomeAssessmentModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      label: "",
      file: null,
      disabled: false,

      fileError: false,
    };
  }

  onChange(field, value) {
    this.setState({ [field]: value });
  }

  disabled() {
    return (
      Util.emptyString(this.state.label) ||
      !this.state.file ||
      this.state.disabled ||
      this.state.fileError
    );
  }

  onDropFile(file) {
    this.setState({ file: file });

    if (
      this.props.patient.home_assessment.find((p) => p.file_name === file.name)
    ) {
      this.setState({
        fileError: (
          <FormattedMessage
            id="File.Already.Exists"
            values={{ fileName: file.name }}
          />
        ),
      });
    } else {
      this.setState({ fileError: false });
    }
  }

  onSubmit() {
    if (this.disabled()) return;

    const { patient } = this.props;

    if (!patient) return;

    this.setState({ disabled: true });

    const successCallback = () => {
      var clonedPatient = Util.shallowClone(patient);
      if (!clonedPatient.home_assessment) clonedPatient.home_assessment = [];

      clonedPatient.home_assessment.push({
        title: this.state.label,
        date: Date.now(),
        file_name: this.state.file.name,
        type: "external",
      });

      this.props.onUpdHomeAssessment(clonedPatient, () => this.props.close());
    };

    let formData = new FormData();
    formData.append(this.state.file.name, this.state.file);

    this.props.onAddHomeAssessmentDocumentFile(
      patient._id,
      formData,
      successCallback,
    );
  }

  render() {
    //this.props.intl.formatMessage({ id: "Expenses" })
    return (
      <div className="modal-bg show">
        <Modal
          show={true}
          onHide={() => this.props.close()}
          backdrop={"static"}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <FormattedMessage id="Report.External.Add" />
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "Title" })}
                htmlFor="label"
                className="col-12 col-md-5 col-form-label"
                required
              />
              <div className="col-12 col-sm-7">
                <input
                  className="form-control"
                  type="text"
                  id="label"
                  autoComplete="off"
                  value={this.state.label}
                  onChange={(e) => this.onChange("label", e.target.value)}
                />
              </div>
            </div>

            <FileDropZone
              onDropFile={(file) => this.onDropFile(file)}
              acceptedExtensions={["pdf", "doc", "docx", "txt", "opt"]}
              multiple={false}
            />
            <div className="w-100 text-center">
              <small className="text-danger">{this.state.fileError}</small>
            </div>

            <div className="row mt-5">
              <div className="col-12 col-lg-6 offset-lg-3">
                <button
                  className="btn btn-info btn-block"
                  disabled={this.disabled()}
                  onClick={() => this.onSubmit()}
                >
                  <FormattedMessage id="Add" />
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    //
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdHomeAssessment: (patient, successCallback) =>
      dispatch(updHomeAssessment(patient, successCallback)),
    onAddHomeAssessmentDocumentFile: (patientId, file, successCallback) =>
      dispatch(addHomeAssessmentDocumentFile(patientId, file, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(AddHomeAssessmentModal));
