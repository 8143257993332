import { combineReducers } from "redux";
import user from "./user/userReducer";
import i18n from "./i18n/i18nReducer";
import clients from "./clients/clientsReducer";
import establishments from "./establishments/establishmentsReducer";
import establishmentTypes from "./configurables/establishmentTypesReducer";
import collaboratorTypes from "./configurables/collaboratorTypesReducer";
import collaborators from "./collaborators/collabotarorsReducer";
import establishment from "./establishment/establishmentReducer";
import admin from "./admin/adminReducer";
import company from "./company/companyReducer";
import generalSettings from "./settings/generalSettings/generalSettingsReducer";
import url from "./authentication/authentication";
import patients from "./patients/patientsReducer";
import expressionNeedsTypes from "./configurables/expressionNeedsTypesReducer";
import trials from "./trials/trialsReducer";
import anthropometricMeasurement from "./anthropometricMeasurements/anthropometricMeasurementsReducer";
import homeAssessment from "./homeAssessments/homeAssessmentsReducer";
import bundles from "./bundles/bundlesReducer";
import trialPerceptions from "./settings/trialPerceptions/trialPerceptionsReducer";
import calendarEvents from "./calendarEvents/calendarEventsReducer";
import savs from "./savs/savsReducer";
import rgpd from "./rgpd/rgpdReducer";
import expressionNeeds from "./expressionNeeds/expressionNeedsReducer";
import brands from "./configurables/brandsReducer";
import models from "./configurables/modelsReducer";

import { LOGOUT } from "../actions/authentication/authentication";

const appReducer = combineReducers({
  user: user,
  i18n: i18n,
  clients: clients,
  establishments: establishments,
  establishmentTypes: establishmentTypes,
  collaboratorTypes: collaboratorTypes,
  collaborators: collaborators,
  establishment: establishment,
  admin: admin,
  generalSettings: generalSettings,
  url: url,
  company: company,
  patients: patients,
  expressionNeedsTypes: expressionNeedsTypes,
  trials: trials,
  anthropometricMeasurement: anthropometricMeasurement,
  homeAssessment: homeAssessment,
  bundles: bundles,
  trialPerceptions: trialPerceptions,
  calendarEvents: calendarEvents,
  savs: savs,
  expressionNeeds: expressionNeeds,
  rgpd: rgpd,
  brands: brands,
  models: models,
});

const urlReducer = (state, action) => {
  if (action.type === LOGOUT) {
    // Wipe all redux state
    state = undefined;
  }

  return appReducer(state, action);
};

export default urlReducer;
