import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import Roles from "../../enums/Roles";
import AddCalendarEventModal from "./AddCalendarEventModal";
import {
  getCalendarEvents,
  getCalendarEventsAdmin,
} from "../../actions/calendarEvents/calendarEvents";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";

// ================================= CALENDAR ================================== //
import "moment/locale/en-gb";
import "moment/locale/fr";
import Util from "../../util/Util";

// ================================= CALENDAR ================================== //
const localizer = momentLocalizer(moment);

// - Modify the BE routes so that they return the correct calendarEvents (PSDM sees all, SalesRep only sees events from his centres etc.)

class CustomCalendar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
      view: "month",
      date: new Date(),
      establishmentId: "",
    };
  }

  getEstablishmentId() {
    const { user } = this.props;
    if (!user) return null;

    var establishmentId = null;

    if (user.role === Roles.CLIENT) {
      establishmentId = user.establishment_id;
    } else {
      if (
        this.state.establishmentId &&
        !Util.emptyString(this.state.establishmentId)
      )
        establishmentId = this.state.establishmentId;
    }

    return establishmentId;
  }

  componentDidMount() {
    if (
      this.props.user.role === Roles.ADMIN ||
      this.props.user.role === Roles.SALES_REP
    )
      this.props.onGetCalendarEventsAdmin();
    else this.props.onGetCalendarEvents();
  }

  openModal(calendarEvent) {
    var establishmentId = this.getEstablishmentId();
    if (!establishmentId) return;

    this.setState({
      modal: (
        <AddCalendarEventModal
          establishmentId={establishmentId}
          calendarEvent={calendarEvent}
          close={() => this.closeModal()}
        />
      ),
    });
  }

  closeModal() {
    this.setState({ modal: null });
  }

  // ===========================================================
  //                      CALENDAR METHODS
  // ===========================================================

  onRangeChange = (data) => {
    if (data.length === 7) this.setState({ view: "week" });
    else if (data.start && data.end) this.setState({ view: "month" });
  };

  onNavigate(date, weekOrMonth) {
    this.setState({ view: weekOrMonth, date });
  }

  // BigCalendar expects a certain format of data. Let's format our calendarEvents here
  formatEvents(calendarEvents, establishmentId) {
    // console.log(calendarEvents);
    if (!calendarEvents || calendarEvents.length === 0) return [];

    const { patients } = this.props;

    var events = [];

    for (let calendarEvent of calendarEvents) {
      //   console.log("calendarEvent", calendarEvent);
      const eventPatient = patients.find(
        (p) => p._id === calendarEvent.patient_id,
      );
      if (!eventPatient) continue;
      if (
        eventPatient.establishment_id.toString() !== establishmentId.toString()
      )
        continue;

      let event = {};

      // Dates
      event.start = new Date(calendarEvent.start_date);
      event.end = new Date(calendarEvent.end_date);

      event.hexColor = calendarEvent.type !== 0 ? "#fff" : "#FF4500";
      event._calendarEvent = calendarEvent; // Keep ref of the original object

      // Title
      const patient = this.props.patients.find(
        (p) => p._id.toString() === calendarEvent.patient_id.toString(),
      );
      event.title = patient.first_name + " " + patient.name;

      events.push(event);
    }

    return events;
  }

  render() {
    const { user, calendarEvents, establishments } = this.props;
    const isClient = user.role === Roles.CLIENT;

    // Admins can see all establishments, thus must have a filter. Clients only see their establishment
    var establishmentFilter = null;
    if (
      this.props.user.role === Roles.ADMIN ||
      this.props.user.role === Roles.SALES_REP
    ) {
      const establishmentsNode = establishments.map((establishment) => (
        <option key={establishment._id} value={establishment._id}>
          {establishment.name}
        </option>
      ));

      establishmentFilter = (
        <div className="mx-auto" style={{ maxWidth: 400 }}>
          <select
            className="form-control w-100 selectlist"
            onChange={(e) => {
              this.setState({ establishmentId: e.target.value });
            }}
          >
            <option value="">
              {this.props.intl.formatMessage({ id: "Select" })}
            </option>
            {establishmentsNode}
          </select>
        </div>
      );
    }

    /*
    var eventStyleGetter = (event, start, end, isSelected) => {
      var backgroundColor = event.hexColor;
      var style = {
        backgroundColor: "lightgreen",
        borderRadius: "0px",
        opacity: 0.8,
        color: "black",
        border: "0px",
        display: "block"
      };
      return style;
    };
    */

    var establishmentId = this.getEstablishmentId();

    // i18n
    const messages = {
      allDay: <FormattedMessage id="Calendar.AllDay" />,
      previous: <FormattedMessage id="Calendar.Previous" />,
      next: <FormattedMessage id="Calendar.Next" />,
      today: <FormattedMessage id="Calendar.Today" />,
      month: <FormattedMessage id="Calendar.Month" />,
      week: <FormattedMessage id="Calendar.Week" />,
      day: <FormattedMessage id="Calendar.Day" />,
      agenda: <FormattedMessage id="Calendar.Agenda" />,
      date: <FormattedMessage id="Calendar.Date" />,
      time: <FormattedMessage id="Calendar.Time" />,
      event: <FormattedMessage id="Calendar.Event" />,
      showMore: (total) => `+ ${total} ` + <FormattedMessage id="Others" />,
    };
    // console.log(calendarEvents)
    return (
      <div>
        {establishmentFilter}

        {establishmentId && (
          <div>
            <div className="col-12">
              {(this.props.user.role === Roles.ADMIN ||
                this.props.user.role === Roles.SALES_REP ||
                this.props.user.role === Roles.CLIENT) && (
                <button
                  className="btn btn-info m-auto hoverable"
                  onClick={(e) => this.openModal()}
                >
                  {!isClient ? (
                    <FormattedMessage id="Add.Calendar.Event" />
                  ) : (
                    <FormattedMessage id="Ask.New.Trial" />
                  )}
                </button>
              )}
            </div>

            <div className="mt-4 p-3" style={{ height: "800px", zIndex: 0 }}>
              <Calendar
                renderable
                selectable
                step={60}
                localizer={localizer}
                defaultDate={new Date()}
                messages={messages}
                defaultView={"month"}
                events={this.formatEvents(calendarEvents, establishmentId)}
                view={this.state.view}
                views={["week", "month"]}
                culture={"fr"}
                date={this.state.date}
                eventPropGetter={(event) => ({
                  style: {
                    backgroundColor:
                      event._calendarEvent.notValidate === true
                        ? "#607d8b"
                        : "#4caf50",
                  },
                })}
                onView={() => {}}
                onNavigate={(date, weekOrMonth, todayPrevOrNext) =>
                  this.onNavigate(date, weekOrMonth, todayPrevOrNext)
                }
                onRangeChange={(dataRange) => this.onRangeChange(dataRange)}
                onSelectEvent={(event) => this.openModal(event._calendarEvent)}
                // onSelectSlot={({ start, end }) => {}}
              />
            </div>

            {this.state.modal}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    patients: state.patients,
    calendarEvents: state.calendarEvents,
    establishments: state.establishments,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetCalendarEventsAdmin: () => dispatch(getCalendarEventsAdmin()),
    onGetCalendarEvents: () => dispatch(getCalendarEvents()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(CustomCalendar));
