import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { removeBlocNotePatient } from "../../actions/patients/patients";
import DateUtil from "../../util/DateUtil";
import { UncontrolledTooltip } from "reactstrap";
import AddPatientNoteModal from "./AddPatientNoteModal";
import ConfirmationModal from "../sub/modals/ConfirmationModal";
import { Alert } from "react-bootstrap";

class PatientNotes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
    };
  }

  close() {
    this.setState({
      modal: null,
    });
  }

  openModal(noteId) {
    let note = this.props.patient.bloc_note.find((n) => n._id === noteId);
    this.setState({
      modal: (
        <AddPatientNoteModal
          patient={this.props.patient}
          note={note}
          close={() => this.close()}
        />
      ),
    });
  }

  openConfModal(noteId) {
    let modalTitle = <FormattedMessage id="Confirmation" />;
    let modalContent = <FormattedMessage id="Confirm.Note.Removal" />;

    this.setState({
      modal: (
        <ConfirmationModal
          title={modalTitle}
          content={modalContent}
          successCallback={() => this.deleteNote(noteId)}
          closeModal={() => this.close()}
        />
      ),
    });
  }

  deleteNote(noteId) {
    this.props.onRemoveBlocNotePatient({
      patientId: this.props.patient._id,
      blocNoteId: noteId,
    });
  }

  render() {
    return (
      <div>
        <button
          className="btn btn-info m-auto hoverable"
          onClick={(e) => this.openModal(null)}
        >
          <FormattedMessage id="Patient.Note.Add" />
        </button>

        {(!this.props.patient.bloc_note ||
          this.props.patient.bloc_note.length === 0) && (
          <Alert variant="secondary" className="mt-3">
            <FormattedMessage id="Empty.Note" />
          </Alert>
        )}

        {this.props.patient.bloc_note.length > 0 && (
          <table className="table tablee4vhp mt-3">
            <thead>
              <tr className="tablerowhead">
                <th className="col-5">
                  <FormattedMessage id="Title" />
                </th>
                <th className="col-4">
                  <FormattedMessage id="Date" />
                </th>
                <th className="col-3 text-center">
                  <FormattedMessage id="Actions" />
                </th>
              </tr>
            </thead>
            <tbody>
              {this.props.patient.bloc_note.map((note, i) => {
                return (
                  <tr key={i} className="tre4vhp">
                    <td className="col-5">{note.title}</td>
                    <td className="col-4">
                      {DateUtil.toDateWithHour(note.date)}
                    </td>
                    <td className="col-3 text-center">
                      <i
                        id={"modify-" + note._id}
                        className="fa fa-edit icon-big"
                        onClick={(e) => this.openModal(note._id)}
                      ></i>
                      <UncontrolledTooltip
                        delay={{ show: 0, hide: 0 }}
                        placement="top"
                        target={"modify-" + note._id}
                      >
                        <FormattedMessage id="Modify" />
                      </UncontrolledTooltip>
                      <i
                        id={"delete-" + note._id}
                        className="fa fa-trash icon-big"
                        onClick={(e) => {
                          this.openConfModal(note._id);
                        }}
                      ></i>
                      <UncontrolledTooltip
                        delay={{ show: 0, hide: 0 }}
                        placement="top"
                        target={"delete-" + note._id}
                      >
                        <FormattedMessage id="Delete" />
                      </UncontrolledTooltip>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
        {this.state.modal}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    //
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onRemoveBlocNotePatient: (patient, successCallback) =>
      dispatch(removeBlocNotePatient(patient, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(PatientNotes));
