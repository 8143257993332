import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import PatientModal from "./PatientModal";
import { deletePatient } from "../../actions/patients/patients";
import { UncontrolledTooltip } from "reactstrap";
import DateUtil from "../../util/DateUtil";
import Maths from "../../util/Maths";
import { Doughnut } from "react-chartjs-2";
import { getPatients } from "../../actions/patients/patients";
import { Alert } from "react-bootstrap";

class SAV extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      patientFilter: "",
      centerFilter: "",
      therapistFilter: "",

      modal: null,
    };
  }

  openModal(patient, section) {
    this.setState({ modal: null }, () =>
      this.setState({
        modal: (
          <PatientModal
            section={section}
            isOpen={true}
            patientId={patient ? patient : null}
            openModal={(patient) => this.openModal(patient)}
            close={() => this.closeModal()}
          />
        ),
      }),
    );
  }

  closeModal() {
    this.setState({ modal: null });
  }

  render() {
    const { user, patients, savs, bundles } = this.props;
    if (!user) return null;

    const savsNode = savs
      .filter(
        (savs) =>
          DateUtil.daysBetween(
            DateUtil.addYears(savs.start_date, 1),
            new Date(),
          ) < 30 && DateUtil.addYears(savs.start_date, 1) > new Date(), // console.log('savs', savs)
      )
      .map((sav) => {
        const bundle = bundles.find((b) => b._id === sav.bundle_id);
        if (!bundle) return null;
        const first_use = sav.documents.sort((a, b) =>
          a.date > b.date ? 1 : -1,
        );

        const renewalDate = DateUtil.addYears(sav.start_date, 1);
        const daysToRenewal = DateUtil.daysBetween(renewalDate, new Date());
        var renewalDateClassname = "";
        if (daysToRenewal < 30 && daysToRenewal > 0)
          renewalDateClassname = "text-warning";

        //var leftAmount = Maths.round(parseFloat(bundle.amount_TTC));

        var usedAmountTotal = 0;
        if (sav.documents) {
          for (let doc of sav.documents)
            usedAmountTotal += Maths.round(doc.montant_TTC);
        }
        var amountLeftTotal = Maths.round(
          Maths.round(bundle.amount_TTC) - Maths.round(usedAmountTotal),
        );

        var lastDate = null;
        if (sav.documents && sav.documents.length > 0) {
          const sorted = sav.documents.sort(
            (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
          );
          lastDate = sorted[sorted.length - 1].date;
        }

        var data = {
          labels: [
            this.props.intl.formatMessage({ id: "Used" }) +
              ": " +
              Maths.round(usedAmountTotal) +
              "€",
            this.props.intl.formatMessage({ id: "Left" }) +
              ": " +
              Maths.round(amountLeftTotal) +
              "€",
          ],
          datasets: [
            {
              data: [usedAmountTotal, amountLeftTotal],
              backgroundColor: ["#DC143C", "green"],
              pointBackgroundColor: ["#DC143C", "green"],
              pointBorderColor: ["rgba(0, 0, 0, 0)"],
              borderColor: ["#DC143C", "green"],
              borderWidth: [2],
            },
          ],
        };
        return (
          <React.Fragment key={sav._id}>
            <tr className={daysToRenewal <= 0 ? "text-muted font-italic" : ""}>
              <td>
                {patients.filter(
                  (patient) => patient._id === sav.patient_id,
                )[0] !== undefined &&
                  patients.filter(
                    (patient) => patient._id === sav.patient_id,
                  )[0].first_name +
                    " " +
                    patients.filter(
                      (patient) => patient._id === sav.patient_id,
                    )[0].name}
              </td>

              <td className="text-left">
                <strong>{bundle.select_bundle_SAV.split("-")[0]}</strong> <br />
                {bundle.select_bundle_SAV.split("-")[1]}
              </td>

              <td>
                <Doughnut height={100} data={data} />
              </td>

              <td>
                {lastDate !== null && lastDate !== undefined && (
                  <div>
                    <strong>
                      <FormattedMessage id="First" /> :
                    </strong>{" "}
                    {DateUtil.toDate(first_use[0].date)} <br />
                    <strong>
                      <FormattedMessage id="Last" /> :
                    </strong>{" "}
                    {lastDate && DateUtil.toDate(lastDate)}
                  </div>
                )}
              </td>

              <td className={renewalDateClassname}>
                <strong>
                  <FormattedMessage id="Start" /> : <br />{" "}
                </strong>{" "}
                {DateUtil.toDate(sav.start_date)}
                <br />
                <strong>
                  <FormattedMessage id="Renewal" /> :{" "}
                </strong>
                {DateUtil.toDate(renewalDate)}
              </td>

              <td className="col text-center">
                <i
                  id={"display-sav-" + sav.patient_id}
                  className="fa fa-eye icon-big"
                  onClick={() => this.openModal(sav.patient_id, "sav")}
                />
                <UncontrolledTooltip
                  delay={{ show: 0, hide: 0 }}
                  placement="top"
                  target={"display-sav-" + sav.patient_id}
                >
                  <FormattedMessage id="Display" />
                </UncontrolledTooltip>
              </td>
            </tr>
          </React.Fragment>
        );
      });

    return (
      <React.Fragment>
        {savsNode.length !== 0 ? (
          <table className="table tablee4vhp">
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="Patient" />
                </th>
                <th>
                  <FormattedMessage id="Designation" />
                </th>
                <th>
                  <FormattedMessage id="Global.Amount" />
                </th>
                <th>
                  <FormattedMessage id="Use" />
                </th>
                <th>
                  <FormattedMessage id="Calendar.Date" />
                </th>
                <th>
                  <FormattedMessage id="Actions" />
                </th>
              </tr>
            </thead>
            <tbody>{savsNode}</tbody>
          </table>
        ) : (
          <Alert variant="success">
            <i className="fa fa-check-circle"></i>{" "}
            <FormattedMessage id="No.Sav.Expiration" />
          </Alert>
        )}

        {!this.props.limit && this.paginator.render()}

        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    patients: state.patients,
    user: state.user,
    bundles: state.bundles,
    savs: state.savs,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDeletePatient: (patientId) => dispatch(deletePatient(patientId)),
    onGetPatient: (patientId) => dispatch(getPatients(patientId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SAV));
