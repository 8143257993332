import APIUrl from "../../APIUrl";
import axios from "axios";
// import Role from '../../enums/Roles';

export const GET_EXPRESSION_NEEDS = "GET_EXPRESSION_NEEDS";

function getExpressionNeedsAction(expressionNeeds) {
  return { type: GET_EXPRESSION_NEEDS, expressionNeeds: expressionNeeds };
}

export const getExpressionsNeeds = function () {
  return function (dispatch) {
    return axios
      .get(APIUrl.getExpressionsNeeds)
      .then(function (response) {
        if (response) dispatch(getExpressionNeedsAction(response.data));
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const addExpressionNeeds = function (expressionNeeds, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addExpressionNeeds, expressionNeeds)
      .then(function (response) {
        dispatch(getExpressionsNeeds());
        if (successCallback) successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const addExpressionNeedsPhotos = function (
  expressionNeedsId,
  patientId,
  photos,
  successCallback,
) {
  return function (dispatch) {
    return axios
      .post(
        APIUrl.addExpressionNeedsPhotos + patientId + "/" + expressionNeedsId,
        photos,
        { headers: { "Content-Type": "multipart/form-data" } },
      )
      .then(function (response) {
        dispatch(getExpressionsNeeds());
        successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const deleteExpressionNeed = function (
  patientId,
  expressionNeedId,
  successCallback,
) {
  var data = {
    patientId: patientId,
    expressionNeedId: expressionNeedId,
  };
  return function (dispatch) {
    return axios
      .post(APIUrl.deleteExpressionNeed, data)
      .then(function (response) {
        dispatch(getExpressionsNeeds());
        if (successCallback) return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};
