import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import Util from "../../../../util/Util";
import { addTrial } from "../../../../actions/trials/trials";
import CustomLabel from "../../../sub/CustomLabel";

class TrialCreatePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dateStartError: null,
      dateEndError: null,
      duplicateTitleError: null,
    };
  }

  disabled() {
    return (
      this.state.dateStartError ||
      this.state.duplicateTitleError ||
      this.state.dateEndError ||
      Util.emptyString(this.props.trial.title) ||
      Util.emptyString(this.props.trial.date_start) ||
      Util.emptyString(this.props.trial.date_end) ||
      Util.emptyString(this.props.trial.loan_material) ||
      this.props.trial.disabled
    );
  }

  onChange(field, value) {
    this.props.setState({ [field]: value });

    if (!this.props.trial) return null;

    let userDate = value ? new Date(value).getTime() : "";
    let dateStart = this.props.trial.date_start
      ? new Date(this.props.trial.date_start).getTime()
      : userDate;
    let dateEnd = this.props.trial.date_end
      ? new Date(this.props.trial.date_end).getTime()
      : userDate;
    // let dateNow = new Date().getTime();

    if (value) {
      if (field === "title") {
        if (this.props.trials.find((trial) => trial.title === value)) {
          this.setState({
            duplicateTitleError: (
              <FormattedMessage id="Invalid.Duplicate.Title" />
            ),
          });
        } else {
          this.setState({ duplicateTitleError: null });
        }
      }

      if (field === "date_start") {
        if (userDate > dateEnd) {
          this.setState({
            dateStartError: <FormattedMessage id="Invalid.Date" />,
            dateEndError: null,
          });
        } else {
          this.setState({ dateStartError: null, dateEndError: null });
        }
      } else if (field === "date_end") {
        if (userDate < dateStart) {
          this.setState({
            dateEndError: <FormattedMessage id="Invalid.Date" />,
            dateStartError: null,
          });
        } else {
          this.setState({ dateStartError: null, dateEndError: null });
        }
      }
    }
  }

  onSubmit() {
    if (this.disabled()) return;

    const { trial, patient } = this.props;

    let trialToSend = this.props.trialToSend(trial);

    if (!trialToSend || !trial) return;

    this.setState({ disabled: true });

    const successCallback = (trial) => {
      if (
        !trial ||
        !this.props.trial ||
        !this.props.trial.form_data ||
        this.props.trial.form_data.length === 0
      ) {
        this.props.closeModal();
        return null;
      }

      this.props.onAddTrialFiles(
        trial._id,
        patient._id,
        this.props.trial.form_data,
        () => this.props.closeModal(),
      );
    };

    this.props.onAddTrial(trialToSend, successCallback);
    //this.props.onAddTrial(trial, () => this.props.closeModal());
  }

  getBrand(brandId) {
    for (let brand of this.props.brands) {
      if (brand._id === brandId) return brand;
    }
  }

  getModel(modelId) {
    for (let model of this.props.models) {
      if (model._id === modelId) return model;
    }
  }

  render() {
    const { trial, patient } = this.props;

    return (
      <>
        <div className="form-group row">
          <CustomLabel
            label={this.props.intl.formatMessage({ id: "Title" })}
            htmlFor="title"
            className="col-12 col-sm-5 col-form-date-end"
            required
          />
          <div className="col-12 col-sm-7">
            <input
              className="form-control"
              type="text"
              id="title"
              autoComplete="off"
              value={trial.title}
              onChange={(e) => this.onChange("title", e.target.value)}
            />
            <div className="text-danger">
              <small>{this.state.duplicateTitleError}</small>
            </div>
          </div>
        </div>

        <div className="form-group row">
          <CustomLabel
            label={this.props.intl.formatMessage({ id: "Date.Start" })}
            htmlFor="date_start"
            className="col-12 col-sm-5 col-form-date-start"
            required
          />
          <div className="col-12 col-sm-7">
            <input
              className="form-control"
              type="date"
              id="date-start"
              autoComplete="off"
              value={trial.date_start}
              onChange={(e) => this.onChange("date_start", e.target.value)}
            />
            <div className="text-danger">
              <small>{this.state.dateStartError}</small>
            </div>
          </div>
        </div>

        <div className="form-group row">
          <CustomLabel
            label={this.props.intl.formatMessage({ id: "Date.End" })}
            htmlFor="date_end"
            className="col-12 col-sm-5 col-form-date-end"
            required
          />
          <div className="col-12 col-sm-7">
            <input
              className="form-control"
              type="date"
              id="date-end"
              autoComplete="off"
              value={trial.date_end}
              onChange={(e) => this.onChange("date_end", e.target.value)}
            />
            <div className="text-danger">
              <small>{this.state.dateEndError}</small>
            </div>
          </div>
        </div>

        <div className="form-group row">
          <CustomLabel
            label={this.props.intl.formatMessage({ id: "Loan.Material" })}
            htmlFor="loan_material"
            className="col-12 col-sm-5 col-form-loin-material"
            required
          />
          <div className="col-12 col-sm-7">
            <select
              id="loan-material"
              className="form-control col-12 mx-auto selectlist"
              onChange={(e) => this.onChange("loan_material", e.target.value)}
            >
              <option></option>
              {patient &&
                patient.wheelchairs &&
                patient.wheelchairs.length > 0 &&
                patient.wheelchairs.map((wheelchair) => {
                  return (
                    <option key={wheelchair._id} value={wheelchair._id}>
                      {this.getBrand(wheelchair.brand_id).name +
                        " - " +
                        this.getModel(wheelchair.model_id).name}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>

        <div className="form-group row">
          <CustomLabel
            label={this.props.intl.formatMessage({ id: "Status" })}
            htmlFor="status"
            className="col-12 col-md-5 col-form-label"
          />
          <div className="col-12 col-sm-7">
            <select
              className="form-control d-inline"
              id="status"
              onChange={(e) => this.onChange("status", e.target.value)}
            >
              <option value=""></option>
              <option value={this.props.intl.formatMessage({ id: "Adapted" })}>
                {this.props.intl.formatMessage({ id: "Adapted" })}
              </option>
              <option
                value={this.props.intl.formatMessage({ id: "In.Progress" })}
              >
                {this.props.intl.formatMessage({ id: "In.Progress" })}
              </option>
              <option value={this.props.intl.formatMessage({ id: "Improper" })}>
                {this.props.intl.formatMessage({ id: "Improper" })}
              </option>
            </select>
          </div>
        </div>

        <div className="form-group row">
          <div className="col-12 col-lg-6 offset-lg-3">
            <button
              className="col-12 btn btn-info btn-block"
              disabled={this.disabled()}
              onClick={() => this.onSubmit()}
            >
              <FormattedMessage id="Patient.Trial.Add" />
            </button>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    clients: state.clients,
    brands: state.brands,
    models: state.models,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddTrial: (trial, successCallback) =>
      dispatch(addTrial(trial, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(TrialCreatePage));
