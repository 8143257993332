import APIUrl from "../../APIUrl";
import axios from "axios";
import Role from "../../enums/Roles";

export const GET_PATIENTS = "GET_PATIENTS";

function getPatientsAction(patients) {
  return { type: GET_PATIENTS, patients: patients };
}

export const getPatients = function () {
  return function (dispatch) {
    return axios
      .get(APIUrl.getPatients)
      .then(function (response) {
        if (response) dispatch(getPatientsAction(response.data));
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const getPatientsAdmin = function () {
  return function (dispatch) {
    return axios
      .get(APIUrl.getPatientsAdmin)
      .then(function (response) {
        dispatch(getPatientsAction(response.data));
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const addPatient = function (patient, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addPatient, patient)
      .then(function (response) {
        dispatch(getPatientsAdmin());
        if (successCallback) successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const updPatientDocument = function (
  patient,
  successCallback,
  userRole,
) {
  return function (dispatch) {
    return axios
      .post(APIUrl.updPatientDocument, patient)
      .then(function (response) {
        if (userRole === Role.CLIENT) {
          dispatch(getPatients());
        } else {
          dispatch(getPatientsAdmin());
        }

        if (successCallback) successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const updPatient = function (patient, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.updPatient, patient)
      .then(function (response) {
        dispatch(getPatientsAdmin());
        if (successCallback) successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const updateBlocNotePatient = function (patient, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.blocNotePatient, patient)
      .then(function (response) {
        dispatch(getPatientsAdmin());
        if (successCallback) successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const removeBlocNotePatient = function (patient, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.removeBlocNotePatient, patient)
      .then(function (response) {
        dispatch(getPatientsAdmin());
        if (successCallback) successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const updHomeAssessment = function (patient, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.updHomeAssessment, patient)
      .then(function (response) {
        dispatch(getPatientsAdmin());
        if (successCallback) successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const updateEquipmentType = function (dataType, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.updateEquipmentType, dataType)
      .then(function (response) {
        dispatch(getPatientsAdmin());
        if (successCallback) successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const updateHomeAssessmentDocumentData = function (
  dataType,
  successCallback,
) {
  return function (dispatch) {
    return axios
      .post(APIUrl.updateHomeAssessmentDocumentData, dataType)
      .then(function (response) {
        dispatch(getPatientsAdmin());
        if (successCallback) successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const updateShowHomeAssessment = function (dataShow, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.updateShowHomeAssessment, dataShow)
      .then(function (response) {
        dispatch(getPatientsAdmin());
        if (successCallback) successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const addImgEquipmentType = function (patientId, data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addImgEquipmentType + patientId, data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(function (response) {
        dispatch(getPatientsAdmin());
        successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const addWheelchairImg = function (
  patientId,
  wheelchairId,
  data,
  successCallback,
) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addWheelchairImg + patientId + "/" + wheelchairId, data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(function (response) {
        dispatch(getPatientsAdmin());

        if (successCallback) successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const addWheelchair = function (wheelchair, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addWheelchair, wheelchair)
      .then(function (response) {
        dispatch(getPatientsAdmin());
        if (successCallback) successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const deletePatient = function (patientId, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.deletePatient, { patientId })
      .then(function (response) {
        dispatch(getPatientsAdmin());
        if (successCallback) successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const addPatientTechnicalFile = function (
  patientId,
  file,
  successCallback,
) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addPatientTechnicalSheet + patientId, file, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(function (response) {
        return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const deletePatientTechnicalSheet = function (
  patientId,
  sheetId,
  successCallback,
) {
  return function (dispatch) {
    return axios
      .get(APIUrl.deletePatientTechnicalSheet + patientId + "/" + sheetId)
      .then(function (response) {
        dispatch(getPatientsAdmin());
        if (successCallback) return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const deleteWheelchair = function (patientId, wheelchairId) {
  return function (dispatch) {
    return axios
      .post(APIUrl.deleteWheelchair, { patientId, wheelchairId })
      .then(function (response) {
        dispatch(getPatientsAdmin());
        //    if(successCallback) successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const addPatientEstimateFile = function (
  patientId,
  file,
  successCallback,
) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addPatientEstimateFile + patientId, file, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(function (response) {
        return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const deletePatientEstimateFile = function (
  patientId,
  fileId,
  successCallback,
) {
  return function (dispatch) {
    return axios
      .get(APIUrl.deletePatientEstimateFile + patientId + "/" + fileId)
      .then(function (response) {
        dispatch(getPatientsAdmin());
        if (successCallback) return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const addPatientDocumentFile = function (
  patientId,
  file,
  successCallback,
) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addPatientDocument + patientId, file, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(function (response) {
        return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const addHomeAssessmentDocumentFile = function (
  patientId,
  file,
  successCallback,
) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addHomeAssessmentDocument + patientId, file, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(function (response) {
        return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const deletePatientDocument = function (
  userRole,
  patientId,
  documentId,
  successCallback,
) {
  return function (dispatch) {
    return axios
      .get(APIUrl.deletePatientDocument + patientId + "/" + documentId)
      .then(function (response) {
        if (userRole === Role.CLIENT) {
          dispatch(getPatients());
        } else {
          dispatch(getPatientsAdmin());
        }
        if (successCallback) return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const deletePatientSAV = function (
  patientId,
  documentId,
  successCallback,
) {
  return function (dispatch) {
    return axios
      .get(APIUrl.deletePatientSAV + patientId + "/" + documentId)
      .then(function (response) {
        dispatch(getPatientsAdmin());
        if (successCallback) return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const deleteHomeAssessmentDocument = function (
  patientId,
  homeAssessmentId,
  successCallback,
) {
  return function (dispatch) {
    return axios
      .get(
        APIUrl.deleteHomeAssessmentDocument +
          patientId +
          "/" +
          homeAssessmentId,
      )
      .then(function (response) {
        dispatch(getPatientsAdmin());
        if (successCallback) return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const exportPDF = function (patient, successCallback) {
  return function (dispatch) {
    return axios
      .get(APIUrl.exportPDF)
      .then(function (response) {
        dispatch(getPatientsAdmin());
        if (successCallback) return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

// export const addPatientTrialFile = function(patientId, file, successCallback){
//     return function(dispatch){
//         return axios.post(APIUrl.addPatientTrialFile + patientId, file, { headers: {'Content-Type': 'multipart/form-data'} })
//             .then(function(response){
//                 return successCallback(response.data);
//             })
//             .catch(function(err){
//                 throw(err);
//             });
//     };
// };

// export const deletePatientTrialFile = function(patientId, fileId, successCallback){
//     return function(dispatch){
//         return axios.get(APIUrl.deletePatientTrialFile + patientId + "/" + fileId)
//             .then(function(response){
//                 dispatch(getPatientsAdmin());
//                 if(successCallback) return successCallback();
//             })
//             .catch(function(err){
//                 throw(err);
//             });
//     };
// };
